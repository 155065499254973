export const environment = {
	production: true,
	encryptionKey: 'XB85fzadtu3bsja2',
	baseUrl: 'https://up.quickrelease.aero/',
	backendUrl: 'https://apollo.quickrelease.aero/graphql',
	// backendUrl: 'http://localhost:8080/graphql',

	firebaseConfig: {
		apiKey: 'AIzaSyDgnSHoQOmb62o7krEAgL5HevwYWiQxUOs',
		authDomain: 'qr-apollo.firebaseapp.com',
		projectId: 'qr-apollo',
		storageBucket: 'qr-apollo.appspot.com',
		messagingSenderId: '48469546037',
		appId: '1:48469546037:web:1d42aff52a05c783a38d7c',
		measurementId: 'G-R3MKM5T0FZ',
	},
};
