import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  Currency: any;
  Date: any;
  DateTime: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  JSON: any;
  JSONObject: any;
  Long: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};



export type AddressComponent = {
  __typename?: 'AddressComponent';
  long_name?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ApiBookingInput = {
  contactCity?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactLanguage: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  flightId?: Maybe<Scalars['String']>;
  regionId: Scalars['String'];
  passengers?: Maybe<Array<Maybe<ApiPassengerInput>>>;
};

export type ApiPassengerInput = {
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['NonNegativeInt']>;
  child?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['NonNegativeInt']>;
  paymentVoucher?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentVoucherId?: Maybe<Scalars['String']>;
};

export type ApiVoucherInput = {
  adults?: Maybe<Scalars['NonNegativeInt']>;
  children?: Maybe<Scalars['NonNegativeInt']>;
  comments?: Maybe<Scalars['String']>;
  contactCity?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contactLanguage: Scalars['String'];
  contactName: Scalars['String'];
  contactPhone: Scalars['String'];
  expiryDate?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['NonNegativeInt']>;
  paymentDate?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<Scalars['String']>;
  paymentType?: Maybe<Scalars['String']>;
  voucherReference: Scalars['String'];
  voucherType: Scalars['String'];
};

export enum AvailabilityType {
  Peri = 'PERI',
  Date = 'DATE'
}

export type Balloon = {
  __typename?: 'Balloon';
  id: Scalars['ID'];
  balloonTemp?: Maybe<Scalars['PositiveInt']>;
  basketManufacturer?: Maybe<Scalars['String']>;
  basketType?: Maybe<Scalars['String']>;
  basketWeight?: Maybe<Scalars['PositiveInt']>;
  burnerManufacturer?: Maybe<Scalars['String']>;
  burnerType?: Maybe<Scalars['String']>;
  burnerWeight?: Maybe<Scalars['PositiveInt']>;
  callSign?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['PositiveInt']>;
  classGroups?: Maybe<Scalars['String']>;
  cylinder: Array<Maybe<Cylinder>>;
  envelopeHours?: Maybe<Scalars['PositiveInt']>;
  envelopeInspectionDate?: Maybe<Scalars['DateTime']>;
  envelopeManufacturer?: Maybe<Scalars['String']>;
  envelopeType?: Maybe<Scalars['String']>;
  envelopeVolume?: Maybe<Scalars['PositiveInt']>;
  envelopeWeight?: Maybe<Scalars['PositiveInt']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  insuranceDueDate?: Maybe<Scalars['DateTime']>;
  isCurrent?: Maybe<Scalars['Int']>;
  minFuel?: Maybe<Scalars['PositiveInt']>;
  mlm?: Maybe<Scalars['PositiveInt']>;
  mtom?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['DateTime']>;
  balloonWeight?: Maybe<BalloonWeight>;
  classGroup: Array<Maybe<ClassGroup>>;
};

export type BalloonInput = {
  balloonTemp?: Maybe<Scalars['PositiveInt']>;
  basketManufacturer?: Maybe<Scalars['String']>;
  basketType?: Maybe<Scalars['String']>;
  basketWeight?: Maybe<Scalars['PositiveInt']>;
  burnerManufacturer?: Maybe<Scalars['String']>;
  burnerType?: Maybe<Scalars['String']>;
  burnerWeight?: Maybe<Scalars['PositiveInt']>;
  callSign?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['PositiveInt']>;
  classGroups?: Maybe<Scalars['String']>;
  envelopeHours?: Maybe<Scalars['PositiveInt']>;
  envelopeInspectionDate?: Maybe<Scalars['DateTime']>;
  envelopeManufacturer?: Maybe<Scalars['String']>;
  envelopeType?: Maybe<Scalars['String']>;
  envelopeVolume?: Maybe<Scalars['PositiveInt']>;
  envelopeWeight?: Maybe<Scalars['PositiveInt']>;
  insuranceCompany?: Maybe<Scalars['String']>;
  insuranceDueDate?: Maybe<Scalars['DateTime']>;
  minFuel?: Maybe<Scalars['PositiveInt']>;
  mlm?: Maybe<Scalars['PositiveInt']>;
  mtom?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
};

export type BalloonWeight = {
  __typename?: 'BalloonWeight';
  totalWeight: Scalars['PositiveInt'];
  cylindersWeight: Scalars['PositiveInt'];
};


export type Booking = {
  __typename?: 'Booking';
  availabilityFridayEvening?: Maybe<Scalars['Boolean']>;
  availabilityFridayMorning?: Maybe<Scalars['Boolean']>;
  availabilityMondayEvening?: Maybe<Scalars['Boolean']>;
  availabilityMondayMorning?: Maybe<Scalars['Boolean']>;
  availabilitySaturdayEvening?: Maybe<Scalars['Boolean']>;
  availabilitySaturdayMorning?: Maybe<Scalars['Boolean']>;
  availabilitySundayEvening?: Maybe<Scalars['Boolean']>;
  availabilitySundayMorning?: Maybe<Scalars['Boolean']>;
  availabilityThursdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityThursdayMorning?: Maybe<Scalars['Boolean']>;
  availabilityTuesdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityTuesdayMorning?: Maybe<Scalars['Boolean']>;
  availabilityType?: Maybe<AvailabilityType>;
  availabilityWednesdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityWednesdayMorning?: Maybe<Scalars['Boolean']>;
  bookingCode?: Maybe<Scalars['String']>;
  bookingDate?: Maybe<Scalars['DateTime']>;
  city?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLanguage?: Maybe<ContactLanguage>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['PhoneNumber']>;
  endDate?: Maybe<Scalars['DateTime']>;
  daysExpired?: Maybe<Scalars['NonNegativeInt']>;
  external?: Maybe<Scalars['Boolean']>;
  externalSource?: Maybe<Scalars['String']>;
  flight?: Maybe<Flight>;
  flightId?: Maybe<Scalars['ID']>;
  icon?: Maybe<Scalars['String']>;
  iconColor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inviteCount?: Maybe<Scalars['Int']>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']>;
  locationType?: Maybe<LocationType>;
  mailEventLatest?: Maybe<MailEventLatest>;
  mergeCandidates?: Maybe<Array<Maybe<Booking>>>;
  mismatchReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  passengerCounters?: Maybe<PassengerCounters>;
  passengers: Array<Maybe<Passenger>>;
  preferredDate?: Maybe<Scalars['String']>;
  preferredFlights?: Maybe<Array<Maybe<PreferredFlight>>>;
  prepaymentAmount?: Maybe<Scalars['Int']>;
  prepaymentDate?: Maybe<Scalars['DateTime']>;
  region?: Maybe<Region>;
  regionId?: Maybe<Scalars['ID']>;
  removed?: Maybe<Scalars['DateTime']>;
  smsLatest?: Maybe<SmsLatest>;
  smsType?: Maybe<SmsConversationType>;
  smsUnread?: Maybe<Scalars['NonNegativeInt']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<BookingStatus>;
  upUrl?: Maybe<Scalars['String']>;
  logs?: Maybe<Array<Maybe<BookingLog>>>;
  logsMerged?: Maybe<Array<Maybe<BookingLog>>>;
  mails?: Maybe<Array<Maybe<Email>>>;
  mailsMerged?: Maybe<Array<Maybe<Email>>>;
  bookingsMerged?: Maybe<Array<Maybe<Booking>>>;
  invoiceName?: Maybe<Scalars['String']>;
  VATnumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  upcoming?: Maybe<Scalars['Boolean']>;
};

export type BookingCounters = {
  __typename?: 'BookingCounters';
  historical?: Maybe<Scalars['NonNegativeInt']>;
  statusCounters?: Maybe<Array<Maybe<StatusCounter>>>;
  toConfirm?: Maybe<Scalars['NonNegativeInt']>;
  upcoming?: Maybe<Scalars['NonNegativeInt']>;
};

export type BookingData = {
  __typename?: 'BookingData';
  id: Scalars['ID'];
  contactName?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  iconColor?: Maybe<Scalars['String']>;
  passengers?: Maybe<Array<Maybe<PassengerData>>>;
  status?: Maybe<Scalars['String']>;
};

export enum BookingFilter {
  Standard = 'standard',
  ToConfirm = 'toConfirm',
  OpenAmount = 'openAmount',
  AvailableThisYear = 'availableThisYear',
  AvailableNext30Days = 'availableNext30Days',
  FlyingThisYear = 'flyingThisYear',
  FlyingNext30Days = 'flyingNext30Days',
  WithoutFlight = 'withoutFlight'
}

export type BookingInput = {
  availabilityFridayEvening?: Maybe<Scalars['Boolean']>;
  availabilityFridayMorning?: Maybe<Scalars['Boolean']>;
  availabilityMondayEvening?: Maybe<Scalars['Boolean']>;
  availabilityMondayMorning?: Maybe<Scalars['Boolean']>;
  availabilitySaturdayEvening?: Maybe<Scalars['Boolean']>;
  availabilitySaturdayMorning?: Maybe<Scalars['Boolean']>;
  availabilitySundayEvening?: Maybe<Scalars['Boolean']>;
  availabilitySundayMorning?: Maybe<Scalars['Boolean']>;
  availabilityThursdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityThursdayMorning?: Maybe<Scalars['Boolean']>;
  availabilityTuesdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityTuesdayMorning?: Maybe<Scalars['Boolean']>;
  availabilityType?: Maybe<AvailabilityType>;
  availabilityWednesdayEvening?: Maybe<Scalars['Boolean']>;
  availabilityWednesdayMorning?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLanguage?: Maybe<ContactLanguage>;
  contactName: Scalars['String'];
  contactPhone: Scalars['PhoneNumber'];
  endDate?: Maybe<Scalars['DateTime']>;
  external?: Maybe<Scalars['Boolean']>;
  externalSource?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  locationType?: Maybe<LocationType>;
  pax?: Maybe<Scalars['Int']>;
  preferredDate?: Maybe<Scalars['String']>;
  preferredFlights?: Maybe<Array<Maybe<PreferredFlightInput>>>;
  prepaymentAmount?: Maybe<Scalars['Int']>;
  prepaymentDate?: Maybe<Scalars['DateTime']>;
  regionId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<BookingStatus>;
  invoiceName?: Maybe<Scalars['String']>;
  VATnumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BookingLog = {
  __typename?: 'BookingLog';
  id: Scalars['ID'];
  action?: Maybe<BookingLogAction>;
  booking?: Maybe<Booking>;
  date?: Maybe<Scalars['DateTime']>;
  flight?: Maybe<Flight>;
  user?: Maybe<Scalars['String']>;
};

export enum BookingLogAction {
  Dele = 'DELE',
  Remo = 'REMO',
  Adde = 'ADDE',
  Invi = 'INVI',
  Conf = 'CONF',
  Comp = 'COMP',
  Mail = 'MAIL',
  Crea = 'CREA'
}

export type BookingPaymentInput = {
  contactEmail?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['PhoneNumber']>;
  prepaymentAmount?: Maybe<Scalars['Int']>;
  prepaymentDate?: Maybe<Scalars['DateTime']>;
  invoiceName?: Maybe<Scalars['String']>;
  VATnumber?: Maybe<Scalars['String']>;
};

export enum BookingStatus {
  Open = 'OPEN',
  Adde = 'ADDE',
  Invi = 'INVI',
  Conf = 'CONF',
  Comp = 'COMP',
  Canc = 'CANC'
}

export type BookingsData = {
  __typename?: 'BookingsData';
  id?: Maybe<Scalars['ID']>;
  list?: Maybe<Array<Maybe<BookingData>>>;
  count?: Maybe<Scalars['NonNegativeInt']>;
  addedCount?: Maybe<Scalars['NonNegativeInt']>;
  notConfirmedCount?: Maybe<Scalars['NonNegativeInt']>;
  notConfirmedNames?: Maybe<Scalars['String']>;
};


export type Calendar = {
  __typename?: 'Calendar';
  flights?: Maybe<Array<Maybe<Flight>>>;
  events?: Maybe<Array<Maybe<Event>>>;
};

export type CalendarItem = {
  __typename?: 'CalendarItem';
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['JSONObject']>;
  meta?: Maybe<Scalars['JSONObject']>;
};

export type CallSignPopularity = {
  __typename?: 'CallSignPopularity';
  callSign?: Maybe<Scalars['String']>;
  crewPopularity?: Maybe<Scalars['NonNegativeInt']>;
  pilotPopularity?: Maybe<Scalars['NonNegativeInt']>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  child?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  removed?: Maybe<Scalars['DateTime']>;
};

export type CategoryInput = {
  child?: Maybe<Scalars['Boolean']>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type Chart = {
  __typename?: 'Chart';
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ChartType>;
  data?: Maybe<Scalars['JSONObject']>;
  columns?: Maybe<Scalars['JSONObject']>;
  options?: Maybe<Scalars['JSONObject']>;
};

export type ChartColor = {
  __typename?: 'ChartColor';
  backgroundColor?: Maybe<Array<Maybe<Scalars['String']>>>;
  borderColor?: Maybe<Scalars['String']>;
};

export enum ChartType {
  AnnotationChart = 'AnnotationChart',
  AreaChart = 'AreaChart',
  Bar = 'Bar',
  BarChart = 'BarChart',
  BubbleChart = 'BubbleChart',
  Calendar = 'Calendar',
  CandlestickChart = 'CandlestickChart',
  ColumnChart = 'ColumnChart',
  ComboChart = 'ComboChart',
  PieChart = 'PieChart',
  Gantt = 'Gantt',
  Gauge = 'Gauge',
  GeoChart = 'GeoChart',
  Histogram = 'Histogram',
  Line = 'Line',
  LineChart = 'LineChart',
  Map = 'Map',
  OrgChart = 'OrgChart',
  Sankey = 'Sankey',
  Scatter = 'Scatter',
  ScatterChart = 'ScatterChart',
  SteppedAreaChart = 'SteppedAreaChart',
  Table = 'Table',
  Timeline = 'Timeline',
  TreeMap = 'TreeMap',
  Wordtree = 'wordtree'
}

export type ClassGroup = {
  __typename?: 'ClassGroup';
  id: Scalars['String'];
  description: Scalars['String'];
};

export enum ContactLanguage {
  Nl = 'nl',
  Fr = 'fr',
  En = 'en',
  De = 'de'
}

export type Country = {
  __typename?: 'Country';
  id?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type Crew = ICrew & {
  __typename?: 'Crew';
  callSignPopularity?: Maybe<Array<Maybe<CallSignPopularity>>>;
  driverLicense?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['EmailAddress']>;
  emailNotifications?: Maybe<Scalars['EmailAddress']>;
  groundCrew?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  initials?: Maybe<Scalars['String']>;
  isCurrent?: Maybe<Scalars['Int']>;
  isPilot?: Maybe<Scalars['Boolean']>;
  language?: Maybe<ContactLanguage>;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  licenseExtraCrew?: Maybe<Scalars['Boolean']>;
  licenseGas?: Maybe<Scalars['Boolean']>;
  licenseGroupA?: Maybe<Scalars['Boolean']>;
  licenseGroupB?: Maybe<Scalars['Boolean']>;
  licenseGroupC?: Maybe<Scalars['Boolean']>;
  licenseGroupD?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  medicalExpiryDate?: Maybe<Scalars['DateTime']>;
  medicalWeight?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  pilotLicense?: Maybe<PilotLicense>;
  proficiencyLatestBpl?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightA?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightB?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightC?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightD?: Maybe<Scalars['DateTime']>;
  proficiencyLatestGas?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['DateTime']>;
  smsLatest?: Maybe<SmsLatest>;
  smsType?: Maybe<SmsConversationType>;
  smsUnread?: Maybe<Scalars['NonNegativeInt']>;
  status?: Maybe<CrewStatus>;
  totalWeight?: Maybe<Scalars['PositiveInt']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
  userRole?: Maybe<UserRole>;
};

export enum CrewAvailability {
  Unk = 'UNK',
  Yes = 'YES',
  Maybe = 'MAYBE',
  No = 'NO'
}

export type CrewAvailabilityInput = {
  availability: CrewAvailability;
  crewId?: Maybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  period: FlightPeriod;
  type?: Maybe<EventType>;
};

export type CrewCalendarDay = {
  __typename?: 'CrewCalendarDay';
  flightsMorning?: Maybe<CrewDayContent>;
  flightsEvening?: Maybe<CrewDayContent>;
};

export type CrewCalendarItem = {
  __typename?: 'CrewCalendarItem';
  start?: Maybe<Scalars['DateTime']>;
  meta?: Maybe<CrewCalendarDay>;
};

export type CrewDayContent = {
  __typename?: 'CrewDayContent';
  totalFlights?: Maybe<Scalars['Int']>;
  crewEvent?: Maybe<Event>;
  selectedFlights?: Maybe<Array<Maybe<Flight>>>;
};

export type CrewInFlight = {
  __typename?: 'CrewInFlight';
  crew?: Maybe<CrewPerFlight>;
  status?: Maybe<CrewStatus>;
};

export type CrewInput = {
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  email?: Maybe<Scalars['EmailAddress']>;
  emailNotifications?: Maybe<Scalars['EmailAddress']>;
  language?: Maybe<ContactLanguage>;
  pilotLicense?: Maybe<PilotLicense>;
  licenseGroupA?: Maybe<Scalars['Boolean']>;
  licenseGroupB?: Maybe<Scalars['Boolean']>;
  licenseGroupC?: Maybe<Scalars['Boolean']>;
  licenseGroupD?: Maybe<Scalars['Boolean']>;
  licenseGas?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  licenseExtraCrew?: Maybe<Scalars['Boolean']>;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  medicalExpiryDate?: Maybe<Scalars['DateTime']>;
  groundCrew?: Maybe<Scalars['Boolean']>;
  driverLicense?: Maybe<Scalars['Boolean']>;
  medicalWeight?: Maybe<Scalars['PositiveInt']>;
  userRole?: Maybe<UserRole>;
};

export type CrewPerFlight = ICrew & {
  __typename?: 'CrewPerFlight';
  callSignPopularity?: Maybe<Array<Maybe<CallSignPopularity>>>;
  driverLicense?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['EmailAddress']>;
  emailNotifications?: Maybe<Scalars['EmailAddress']>;
  groundCrew?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  language?: Maybe<ContactLanguage>;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  licenseExtraCrew?: Maybe<Scalars['Boolean']>;
  licenseGas?: Maybe<Scalars['Boolean']>;
  licenseGroupA?: Maybe<Scalars['Boolean']>;
  licenseGroupB?: Maybe<Scalars['Boolean']>;
  licenseGroupC?: Maybe<Scalars['Boolean']>;
  licenseGroupD?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  medicalExpiryDate?: Maybe<Scalars['DateTime']>;
  medicalWeight?: Maybe<Scalars['PositiveInt']>;
  mostPopular?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  pilotLicense?: Maybe<PilotLicense>;
  proficiencyLatestBpl?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightA?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightB?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightC?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightD?: Maybe<Scalars['DateTime']>;
  proficiencyLatestGas?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  userRole?: Maybe<UserRole>;
  isCurrent?: Maybe<Scalars['Int']>;
  isPilot?: Maybe<Scalars['Boolean']>;
  totalWeight?: Maybe<Scalars['PositiveInt']>;
  availability?: Maybe<CrewAvailability>;
  otherFlight?: Maybe<Flight>;
  popularity?: Maybe<Scalars['NonNegativeInt']>;
  status?: Maybe<CrewStatus>;
};

export enum CrewStatus {
  Unk = 'UNK',
  Con = 'CON',
  Inv = 'INV',
  Dec = 'DEC'
}


export type Cylinder = {
  __typename?: 'Cylinder';
  emptyWeight?: Maybe<Scalars['PositiveInt']>;
  fullWeight?: Maybe<Scalars['PositiveInt']>;
  id: Scalars['ID'];
  manufacturer?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  removed?: Maybe<Scalars['DateTime']>;
  serialNumber?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['PositiveInt']>;
};

export type CylinderInput = {
  emptyWeight?: Maybe<Scalars['PositiveInt']>;
  fullWeight?: Maybe<Scalars['PositiveInt']>;
  manufacturer?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['PositiveInt']>;
};

export type DashboardOptions = {
  __typename?: 'DashboardOptions';
  toConfirm?: Maybe<Scalars['Boolean']>;
  preferredDateToday?: Maybe<Scalars['Boolean']>;
  recent?: Maybe<Scalars['NonNegativeInt']>;
};


export type DatePart = {
  __typename?: 'DatePart';
  part?: Maybe<DatePartType>;
  text?: Maybe<Scalars['String']>;
};

export enum DatePartType {
  Sunrise = 'SUNRISE',
  Sunset = 'SUNSET'
}


export type ElevationResult = {
  __typename?: 'ElevationResult';
  elevation: Scalars['Float'];
  location?: Maybe<Mapslocation>;
  resolution?: Maybe<Scalars['Float']>;
};

export type Email = {
  __typename?: 'Email';
  attachments: Array<Maybe<EmailAttachment>>;
  bookingId?: Maybe<Scalars['ID']>;
  events?: Maybe<Array<Maybe<EmailEvent>>>;
  flightId?: Maybe<Scalars['ID']>;
  from?: Maybe<EmailAddressObject>;
  html?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['ID']>;
  messageId?: Maybe<Scalars['String']>;
  replyTo?: Maybe<EmailAddressObject>;
  result?: Maybe<Scalars['String']>;
  resultAt?: Maybe<Scalars['DateTime']>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};


export type EmailAddressInput = {
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type EmailAddressObject = {
  __typename?: 'EmailAddressObject';
  email?: Maybe<Scalars['EmailAddress']>;
  name?: Maybe<Scalars['String']>;
};

export type EmailAttachment = {
  __typename?: 'EmailAttachment';
  filename?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type EmailAttachmentInput = {
  filename?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
};

export type EmailEvent = {
  __typename?: 'EmailEvent';
  attempt?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  sg_content_type?: Maybe<Scalars['String']>;
  sg_event_id?: Maybe<Scalars['String']>;
  sg_machine_open?: Maybe<Scalars['String']>;
  sg_message_id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['DateTime']>;
  url?: Maybe<Scalars['URL']>;
  useragent?: Maybe<Scalars['String']>;
};

export type EmailInput = {
  flightId?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  bookingId?: Maybe<Scalars['String']>;
  crewId?: Maybe<Scalars['String']>;
  from?: Maybe<EmailAddressInput>;
  replyTo?: Maybe<EmailAddressInput>;
  to?: Maybe<Scalars['String']>;
  cc?: Maybe<Array<Maybe<EmailAddressInput>>>;
  subject?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  attachments: Array<Maybe<EmailAttachmentInput>>;
  contactName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type EmailLanguages = {
  __typename?: 'EmailLanguages';
  en?: Maybe<EmailTemplateByLanguage>;
  fr?: Maybe<EmailTemplateByLanguage>;
  nl?: Maybe<EmailTemplateByLanguage>;
  de?: Maybe<EmailTemplateByLanguage>;
};

export type EmailLanguagesInput = {
  en?: Maybe<EmailTemplateByLanguageInput>;
  fr?: Maybe<EmailTemplateByLanguageInput>;
  nl?: Maybe<EmailTemplateByLanguageInput>;
  de?: Maybe<EmailTemplateByLanguageInput>;
};

export type EmailSettingsTemplate = {
  __typename?: 'EmailSettingsTemplate';
  id: Scalars['ID'];
  template?: Maybe<EmailLanguages>;
  type?: Maybe<EmailTemplateType>;
  title?: Maybe<Scalars['String']>;
};

export type EmailTemplateByLanguage = {
  __typename?: 'EmailTemplateByLanguage';
  body: Scalars['String'];
  subject: Scalars['String'];
  attachments?: Maybe<Array<Maybe<EmailAttachment>>>;
};

export type EmailTemplateByLanguageInput = {
  body: Scalars['String'];
  subject: Scalars['String'];
  attachments?: Maybe<Array<Maybe<EmailAttachmentInput>>>;
};

export type EmailTemplateInput = {
  template?: Maybe<EmailLanguagesInput>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum EmailTemplateType {
  CrewOtpEmail = 'crewOtpEmail',
  PilotFlightEmail = 'pilotFlightEmail',
  CrewFlightEmail = 'crewFlightEmail',
  BookingConfirmEmail = 'bookingConfirmEmail',
  BookingFlightConfirmEmail = 'bookingFlightConfirmEmail',
  BookingFlightCancelEmail = 'bookingFlightCancelEmail',
  LocationContactEmail = 'locationContactEmail'
}

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  removed?: Maybe<Scalars['DateTime']>;
  type?: Maybe<EventType>;
  availability?: Maybe<CrewAvailability>;
  period?: Maybe<FlightPeriod>;
  crew?: Maybe<Crew>;
};

export type EventData = {
  __typename?: 'EventData';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
};

export type EventInput = {
  description: Scalars['String'];
  date: Scalars['DateTime'];
  type?: Maybe<EventType>;
  crewId?: Maybe<Scalars['ID']>;
};

export enum EventType {
  Opr = 'OPR',
  Cre = 'CRE'
}

export type FilterRange = {
  __typename?: 'FilterRange';
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type FilterRangeInput = {
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type Flight = {
  __typename?: 'Flight';
  _created?: Maybe<Scalars['DateTime']>;
  _modified?: Maybe<Scalars['DateTime']>;
  api?: Maybe<FlightApi>;
  balloon?: Maybe<Balloon>;
  bookings: FlightBookings;
  bookingsData?: Maybe<BookingsData>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  events: Array<Maybe<Event>>;
  eventsData?: Maybe<Array<Maybe<EventData>>>;
  flightTime?: Maybe<Scalars['PositiveInt']>;
  flightDistance?: Maybe<Scalars['Float']>;
  flightHeading?: Maybe<Scalars['Float']>;
  flightsAtSameDay: FlightsAtSameDay;
  flightDuplicates?: Maybe<Array<Maybe<Flight>>>;
  meteo?: Maybe<MeteoblueForecastSlot>;
  groundCrews: Array<Maybe<CrewInFlight>>;
  hour?: Maybe<Scalars['DateTime']>;
  hourMeeting?: Maybe<Scalars['DateTime']>;
  icon: Icon;
  id: Scalars['ID'];
  landingLocation?: Maybe<Scalars['String']>;
  landingTime?: Maybe<Scalars['DateTime']>;
  landingDetails?: Maybe<Scalars['String']>;
  landingLatitude?: Maybe<Scalars['Float']>;
  landingLongitude?: Maybe<Scalars['Float']>;
  liftAvailable?: Maybe<Scalars['Float']>;
  liftDataMismatch?: Maybe<Array<Maybe<Scalars['String']>>>;
  liftNeeded?: Maybe<Scalars['Float']>;
  liftPassengers?: Maybe<Scalars['Float']>;
  liftSpare?: Maybe<Scalars['Float']>;
  location?: Maybe<Location>;
  locationType?: Maybe<LocationType>;
  locationEmail?: Maybe<MailEventLatest>;
  locationSmsLatest?: Maybe<SmsLatest>;
  logs?: Maybe<Array<Maybe<BookingLog>>>;
  meetingPointSameAsTakeOff?: Maybe<Scalars['Boolean']>;
  meetingPointAddress?: Maybe<Scalars['String']>;
  meetingPointLatitude?: Maybe<Scalars['Float']>;
  meetingPointLongitude?: Maybe<Scalars['Float']>;
  meetingPointURL?: Maybe<Scalars['String']>;
  meetingPointCity?: Maybe<Scalars['String']>;
  maximumFlightTime?: Maybe<Scalars['PositiveInt']>;
  maximumHeight?: Maybe<Scalars['PositiveInt']>;
  passengerCounters: FlightPassengerCounters;
  passengersCount?: Maybe<Scalars['Int']>;
  passengersConfirmed?: Maybe<Scalars['Int']>;
  passengersData?: Maybe<PassengerData>;
  passengersOccupancy?: Maybe<Scalars['NonNegativeInt']>;
  period?: Maybe<FlightPeriod>;
  pilot?: Maybe<CrewInFlight>;
  pilotActionNeeded?: Maybe<Scalars['Boolean']>;
  pilotComments?: Maybe<Scalars['String']>;
  pilotSignature?: Maybe<Scalars['String']>;
  pilotSignoff?: Maybe<Scalars['String']>;
  pilotSignOffDate?: Maybe<Scalars['DateTime']>;
  pilotStatus?: Maybe<CrewStatus>;
  plannedFlightTime?: Maybe<Scalars['PositiveInt']>;
  refuelVolume?: Maybe<Scalars['PositiveInt']>;
  removed?: Maybe<Scalars['DateTime']>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  spareFlightTime?: Maybe<Scalars['Int']>;
  status?: Maybe<FlightStatus>;
  skeyesForecast?: Maybe<FlightSkeyesForecast>;
  sunriseSunset?: Maybe<SunriseSunset>;
  takeoffTime?: Maybe<Scalars['DateTime']>;
  tempBalloon?: Maybe<Scalars['PositiveInt']>;
  tempMax?: Maybe<Scalars['Int']>;
  tempTakeoff?: Maybe<Scalars['Int']>;
  trajectory?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectoryCustom?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory1000?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory950?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory925?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory800?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory700?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory600?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory500?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory400?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory300?: Maybe<Array<Maybe<Mapslocation>>>;
  trajectory200?: Maybe<Array<Maybe<Mapslocation>>>;
  vfrClouds?: Maybe<Scalars['Int']>;
  vfrVisual?: Maybe<Scalars['Int']>;
  visibility?: Maybe<VisibilityType>;
  weatherForecastDate?: Maybe<Scalars['DateTime']>;
  weatherPmax?: Maybe<Scalars['Int']>;
  weatherQnh?: Maybe<Scalars['Int']>;
  weatherSource?: Maybe<Scalars['String']>;
  wind10?: Maybe<Scalars['Int']>;
  wind10Direction?: Maybe<Scalars['Int']>;
  wind20?: Maybe<Scalars['Int']>;
  wind20Direction?: Maybe<Scalars['Int']>;
  windGl?: Maybe<Scalars['Int']>;
  windGlDirection?: Maybe<Scalars['Int']>;
  windSummary?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  tagsData?: Maybe<Array<Maybe<TagData>>>;
  upcoming?: Maybe<Scalars['Boolean']>;
};

export type FlightApi = {
  __typename?: 'FlightApi';
  balloonCapacity?: Maybe<Scalars['NonNegativeInt']>;
  balloonName?: Maybe<Scalars['String']>;
  confirmedPassengers?: Maybe<Scalars['NonNegativeInt']>;
  toConfirmPassengers?: Maybe<Scalars['NonNegativeInt']>;
  flightDate?: Maybe<Scalars['String']>;
  flightHour?: Maybe<Scalars['String']>;
  flightId?: Maybe<Scalars['String']>;
  flightNumber?: Maybe<Scalars['NonNegativeInt']>;
  flightPeriod?: Maybe<Scalars['String']>;
  freeCapacity?: Maybe<Scalars['NonNegativeInt']>;
  locationAddress?: Maybe<Scalars['String']>;
  locationCity?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  regionNumber?: Maybe<Scalars['String']>;
  regionName?: Maybe<Scalars['String']>;
};

export type FlightBookings = {
  __typename?: 'FlightBookings';
  id: Scalars['ID'];
  list: Array<Maybe<Booking>>;
  count: Scalars['NonNegativeInt'];
  addedCount: Scalars['NonNegativeInt'];
  notConfirmedCount: Scalars['NonNegativeInt'];
  notConfirmedNames: Scalars['String'];
};

export type FlightCounters = {
  __typename?: 'FlightCounters';
  upcoming?: Maybe<Scalars['NonNegativeInt']>;
  historical?: Maybe<Scalars['NonNegativeInt']>;
  scheduled?: Maybe<Scalars['NonNegativeInt']>;
  statusCounters?: Maybe<Array<Maybe<StatusCounter>>>;
};

export type FlightCrewInput = {
  id?: Maybe<Scalars['ID']>;
  isPilot?: Maybe<Scalars['Boolean']>;
  status?: Maybe<CrewStatus>;
};

export type FlightInput = {
  balloon?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  hour?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['ID']>;
  locationType?: Maybe<LocationType>;
  meetingPointSameAsTakeOff?: Maybe<Scalars['Boolean']>;
  meetingPointAddress?: Maybe<Scalars['String']>;
  meetingPointLatitude?: Maybe<Scalars['Float']>;
  meetingPointLongitude?: Maybe<Scalars['Float']>;
  meetingPointURL?: Maybe<Scalars['String']>;
  meetingPointCity?: Maybe<Scalars['String']>;
  maximumHeight?: Maybe<Scalars['PositiveInt']>;
  period?: Maybe<FlightPeriod>;
  plannedFlightTime?: Maybe<Scalars['PositiveInt']>;
  tempBalloon?: Maybe<Scalars['PositiveInt']>;
  tempTakeoff?: Maybe<Scalars['Int']>;
  vfrClouds?: Maybe<Scalars['Int']>;
  vfrVisual?: Maybe<Scalars['Int']>;
  visibility?: Maybe<VisibilityType>;
  weatherForecastDate?: Maybe<Scalars['DateTime']>;
  weatherQnh?: Maybe<Scalars['Int']>;
  weatherSource?: Maybe<Scalars['String']>;
  wind10?: Maybe<Scalars['Int']>;
  wind10Direction?: Maybe<Scalars['Int']>;
  wind20?: Maybe<Scalars['Int']>;
  wind20Direction?: Maybe<Scalars['Int']>;
  windGl?: Maybe<Scalars['Int']>;
  windGlDirection?: Maybe<Scalars['Int']>;
  windSummary?: Maybe<Scalars['String']>;
  refuelVolume?: Maybe<Scalars['PositiveInt']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FlightPassengerCounters = {
  __typename?: 'FlightPassengerCounters';
  id: Scalars['ID'];
  freeSpots: Scalars['NonNegativeInt'];
  occupancy: Scalars['NonNegativeInt'];
  passengersCount: Scalars['NonNegativeInt'];
  passengersCountAdded: Scalars['NonNegativeInt'];
  passengersCountConfirmed: Scalars['NonNegativeInt'];
  passengersCountInvited: Scalars['NonNegativeInt'];
  passengersWeight: Scalars['NonNegativeInt'];
};

export enum FlightPeriod {
  Mor = 'MOR',
  Eve = 'EVE'
}

export type FlightPeriodCounters = {
  __typename?: 'FlightPeriodCounters';
  flightsCount: Scalars['NonNegativeInt'];
  passengersCount: Scalars['NonNegativeInt'];
  passengersCountAdded: Scalars['NonNegativeInt'];
  passengersCountInvited: Scalars['NonNegativeInt'];
  passengersCountConfirmed: Scalars['NonNegativeInt'];
  freeSpots: Scalars['NonNegativeInt'];
};

export type FlightPeriodCountersPerPeriod = {
  __typename?: 'FlightPeriodCountersPerPeriod';
  id?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  MOR?: Maybe<FlightPeriodCounters>;
  EVE?: Maybe<FlightPeriodCounters>;
};

export type FlightPeriods = {
  __typename?: 'FlightPeriods';
  month?: Maybe<Array<Maybe<FlightPeriodCountersPerPeriod>>>;
  week?: Maybe<Array<Maybe<FlightPeriodCountersPerPeriod>>>;
  day?: Maybe<Array<Maybe<FlightPeriodCountersPerPeriod>>>;
};

export type FlightSkeyesForecast = {
  __typename?: 'FlightSkeyesForecast';
  username?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  forecast?: Maybe<SkeyesForecast>;
};

export enum FlightStatus {
  Sch = 'SCH',
  Rea = 'REA',
  Can = 'CAN',
  Fin = 'FIN'
}

export type FlightsAtSameDay = {
  __typename?: 'FlightsAtSameDay';
  flights: Array<Maybe<Flight>>;
  count: Scalars['NonNegativeInt'];
  names: Scalars['String'];
};

export type GcpipUser = {
  __typename?: 'GCPIPUser';
  uid: Scalars['ID'];
  email: Scalars['String'];
  accessToken: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  customClaims?: Maybe<Scalars['JSON']>;
  userData?: Maybe<User>;
};


export type GcpipUserInput = {
  email: Scalars['String'];
  role?: Maybe<UserRole>;
  displayName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type GeocodeResult = {
  __typename?: 'GeocodeResult';
  address_components?: Maybe<Array<Maybe<AddressComponent>>>;
  formatted_address?: Maybe<Scalars['String']>;
  geometry?: Maybe<Geometry>;
  place_id?: Maybe<Scalars['String']>;
  partial_match?: Maybe<Scalars['Boolean']>;
  plus_code?: Maybe<Pluscode>;
  elevation?: Maybe<Array<Maybe<ElevationResult>>>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Geometry = {
  __typename?: 'Geometry';
  location?: Maybe<Mapslocation>;
  location_type?: Maybe<Scalars['String']>;
  viewport?: Maybe<Viewport>;
};






export type ICrew = {
  callSignPopularity?: Maybe<Array<Maybe<CallSignPopularity>>>;
  driverLicense?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['EmailAddress']>;
  emailNotifications?: Maybe<Scalars['EmailAddress']>;
  groundCrew?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  language?: Maybe<ContactLanguage>;
  licenseExpiryDate?: Maybe<Scalars['DateTime']>;
  licenseExtraCrew?: Maybe<Scalars['Boolean']>;
  licenseGas?: Maybe<Scalars['Boolean']>;
  licenseGroupA?: Maybe<Scalars['Boolean']>;
  licenseGroupB?: Maybe<Scalars['Boolean']>;
  licenseGroupC?: Maybe<Scalars['Boolean']>;
  licenseGroupD?: Maybe<Scalars['Boolean']>;
  licenseNumber?: Maybe<Scalars['String']>;
  medicalExpiryDate?: Maybe<Scalars['DateTime']>;
  medicalWeight?: Maybe<Scalars['PositiveInt']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  pilotLicense?: Maybe<PilotLicense>;
  proficiencyLatestBpl?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightA?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightB?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightC?: Maybe<Scalars['DateTime']>;
  proficiencyLatestFlightD?: Maybe<Scalars['DateTime']>;
  proficiencyLatestGas?: Maybe<Scalars['DateTime']>;
  removed?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  userRole?: Maybe<UserRole>;
};




export type Icon = {
  __typename?: 'Icon';
  sign: Scalars['String'];
  color: Scalars['String'];
};

export type InputLocationOptions = {
  defaultLocationId?: Maybe<Scalars['String']>;
};

export type InputOperatorSettingsBackup = {
  active: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
};

export type InputOperatorSettingsCrew = {
  shareBookings?: Maybe<Scalars['Boolean']>;
  allowFlightSelection?: Maybe<Scalars['Boolean']>;
};

export type InputOperatorSettingsFlights = {
  sunriseOffset?: Maybe<Scalars['NonNegativeInt']>;
  sunsetOffset?: Maybe<Scalars['NonNegativeInt']>;
  meetingTimeOffset?: Maybe<Scalars['NonNegativeInt']>;
};

export type InputOperatorSettingsMyFlight = {
  autoConfirm?: Maybe<Scalars['Boolean']>;
  flights?: Maybe<MyflightFlights>;
  endOfSeason?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InputOperatorSettingsSms = {
  footerEnabled?: Maybe<Scalars['Boolean']>;
};

export type InputOperatorSettingsTrajectoryProfile = {
  duration?: Maybe<Scalars['NonNegativeInt']>;
  level?: Maybe<Scalars['NonNegativeInt']>;
};

export type InputReleaseNote = {
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  documentation?: Maybe<Scalars['String']>;
  oneliner?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};



export type LandingInput = {
  takeoffTime?: Maybe<Scalars['DateTime']>;
  landingTime?: Maybe<Scalars['DateTime']>;
  landingLocation?: Maybe<Scalars['String']>;
  landingDetails?: Maybe<Scalars['String']>;
  landingLatitude?: Maybe<Scalars['Float']>;
  landingLongitude?: Maybe<Scalars['Float']>;
  refuelVolume?: Maybe<Scalars['PositiveInt']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact?: Maybe<LocationContact>;
  country?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  forecasts?: Maybe<Array<Maybe<MeteoblueForecastSlot>>>;
  height?: Maybe<Scalars['PositiveInt']>;
  id: Scalars['ID'];
  isHotAirfield?: Maybe<Scalars['Boolean']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maximumHeight?: Maybe<Scalars['Float']>;
  meetingPointSameAsTakeOff?: Maybe<Scalars['Boolean']>;
  meetingPointAddress?: Maybe<Scalars['String']>;
  meetingPointLatitude?: Maybe<Scalars['Float']>;
  meetingPointLongitude?: Maybe<Scalars['Float']>;
  meetingPointURL?: Maybe<Scalars['String']>;
  meetingPointCity?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  postalCode?: Maybe<Scalars['String']>;
  private?: Maybe<Scalars['Boolean']>;
  region: Array<Maybe<Region>>;
  removed?: Maybe<Scalars['DateTime']>;
};

export type LocationContact = {
  __typename?: 'LocationContact';
  comment?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  language?: Maybe<ContactLanguage>;
  smsTemplate?: Maybe<Scalars['ID']>;
  media?: Maybe<LocationContactMedia>;
};

export type LocationContactInput = {
  comment?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailTemplate?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  language?: Maybe<ContactLanguage>;
  smsTemplate?: Maybe<Scalars['ID']>;
  media?: Maybe<LocationContactMedia>;
};

export enum LocationContactMedia {
  Mail = 'MAIL',
  Sms = 'SMS',
  Nomsg = 'NOMSG'
}

export type LocationInput = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact?: Maybe<LocationContactInput>;
  country?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['PositiveInt']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  maximumHeight?: Maybe<Scalars['Float']>;
  meetingPointSameAsTakeOff?: Maybe<Scalars['Boolean']>;
  meetingPointAddress?: Maybe<Scalars['String']>;
  meetingPointLatitude?: Maybe<Scalars['Float']>;
  meetingPointLongitude?: Maybe<Scalars['Float']>;
  meetingPointURL?: Maybe<Scalars['String']>;
  meetingPointCity?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
};

export type LocationOptions = {
  __typename?: 'LocationOptions';
  defaultLocation?: Maybe<Location>;
};

export enum LocationType {
  Regi = 'REGI',
  Fixe = 'FIXE',
  Priv = 'PRIV'
}



export type MlText = {
  __typename?: 'MLText';
  lang?: Maybe<ContactLanguage>;
  text?: Maybe<Scalars['String']>;
};

export type MlTextInput = {
  lang?: Maybe<ContactLanguage>;
  text?: Maybe<Scalars['String']>;
};

export type MailEventLatest = {
  __typename?: 'MailEventLatest';
  date?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Scalars['String']>;
};

export type Manufacturers = {
  __typename?: 'Manufacturers';
  envelopeManufacturers: Array<Maybe<Scalars['String']>>;
  basketManufacturers: Array<Maybe<Scalars['String']>>;
  burnerManufacturers: Array<Maybe<Scalars['String']>>;
  cylinderManufacturers: Array<Maybe<Scalars['String']>>;
};

export type Mapslocation = {
  __typename?: 'Mapslocation';
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  alt?: Maybe<Scalars['NonNegativeInt']>;
};

export type MeteoblueForecast = {
  __typename?: 'MeteoblueForecast';
  _created?: Maybe<Scalars['DateTime']>;
  profilewind?: Maybe<MeteoblueProfilewind>;
  trendpro?: Maybe<MeteoblueTrendpro>;
  daytime?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  flytime?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  datepart?: Maybe<Array<Maybe<DatePart>>>;
};

export type MeteoblueForecastSlot = {
  __typename?: 'MeteoblueForecastSlot';
  forecast?: Maybe<SlotForecast>;
  slot?: Maybe<Scalars['String']>;
  tempTakeoff?: Maybe<Scalars['Int']>;
  vfrVisual?: Maybe<Scalars['Int']>;
  weatherLocationId?: Maybe<Scalars['String']>;
  weatherQnh?: Maybe<Scalars['Int']>;
  wind10?: Maybe<Scalars['Int']>;
  wind10Direction?: Maybe<Scalars['Int']>;
  wind20?: Maybe<Scalars['Int']>;
  wind20Direction?: Maybe<Scalars['Int']>;
  windGl?: Maybe<Scalars['Int']>;
  windGlDirection?: Maybe<Scalars['Int']>;
};

export type MeteoblueProfileWindData = {
  __typename?: 'MeteoblueProfileWindData';
  time?: Maybe<Array<Maybe<Scalars['String']>>>;
  winddirectionprofile1000_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile200_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile250_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile300_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile350_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile400_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile450_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile500_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile550_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile600_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile650_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile700_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile750_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile800_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile850_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile875_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile900_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile925_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile950_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  winddirectionprofile975_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_1000_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_200_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_250_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_300_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_350_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_400_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_450_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_500_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_550_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_600_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_650_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_700_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_750_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_800_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_850_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_875_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_900_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_925_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_950_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeedprofile_975_mb?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type MeteoblueProfilewind = {
  __typename?: 'MeteoblueProfilewind';
  data_1h?: Maybe<MeteoblueProfileWindData>;
  metadata?: Maybe<Scalars['JSON']>;
  units?: Maybe<Scalars['JSON']>;
};

export type MeteoblueTrendpro = {
  __typename?: 'MeteoblueTrendpro';
  metadata?: Maybe<Scalars['JSON']>;
  trend_1h?: Maybe<MeteoblueTrendproData>;
  units?: Maybe<Scalars['JSON']>;
};

export type MeteoblueTrendproData = {
  __typename?: 'MeteoblueTrendproData';
  cape?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dewpointtemperature?: Maybe<Array<Maybe<Scalars['Float']>>>;
  evapotranspiration?: Maybe<Array<Maybe<Scalars['Float']>>>;
  extraterrestrialradiation_backwards?: Maybe<Array<Maybe<Scalars['Float']>>>;
  ghi_backwards?: Maybe<Array<Maybe<Scalars['Float']>>>;
  gust?: Maybe<Array<Maybe<Scalars['Float']>>>;
  hiclouds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  liftedindex?: Maybe<Array<Maybe<Scalars['Float']>>>;
  lowclouds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  midclouds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  pictocode?: Maybe<Array<Maybe<Scalars['Int']>>>;
  precipitation?: Maybe<Array<Maybe<Scalars['Float']>>>;
  precipitation_probability?: Maybe<Array<Maybe<Scalars['Int']>>>;
  precipitation_spread?: Maybe<Array<Maybe<Scalars['Float']>>>;
  referenceevapotranspiration_fao?: Maybe<Array<Maybe<Scalars['Float']>>>;
  relativehumidity?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sealevelpressure?: Maybe<Array<Maybe<Scalars['Int']>>>;
  skintemperature?: Maybe<Array<Maybe<Scalars['Float']>>>;
  snowfraction?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sunshinetime?: Maybe<Array<Maybe<Scalars['Int']>>>;
  temperature?: Maybe<Array<Maybe<Scalars['Float']>>>;
  temperature_spread?: Maybe<Array<Maybe<Scalars['Float']>>>;
  time?: Maybe<Array<Maybe<Scalars['String']>>>;
  totalcloudcover?: Maybe<Array<Maybe<Scalars['Int']>>>;
  totalcloudcover_spread?: Maybe<Array<Maybe<Scalars['Int']>>>;
  visibility?: Maybe<Array<Maybe<Scalars['Int']>>>;
  winddirection?: Maybe<Array<Maybe<Scalars['Int']>>>;
  windspeed?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windspeed_spread?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAndConfirmBookingsToFlight?: Maybe<Scalars['NonNegativeInt']>;
  addBookingToMyFlight: Scalars['ID'];
  addBookingsToFlight?: Maybe<Scalars['NonNegativeInt']>;
  addCrewToFlightFromCalendar: Scalars['ID'];
  addEmailOut: Scalars['ID'];
  addEmailTemplateLocationContact?: Maybe<EmailSettingsTemplate>;
  addPassengers?: Maybe<Scalars['Int']>;
  addReleaseNote?: Maybe<ReleaseNote>;
  addSmsTemplateLocationContact?: Maybe<SmsTemplate>;
  changeFlightStatus: Flight;
  confirmBookingToFlight: Scalars['ID'];
  confirmBookingToMyFlight: Scalars['ID'];
  copyEmailTemplate?: Maybe<EmailSettingsTemplate>;
  copyFlight: Scalars['ID'];
  copyWeather: Scalars['ID'];
  createBooking: Scalars['ID'];
  createBookingFromVoucher: Scalars['ID'];
  createIcsId?: Maybe<Scalars['String']>;
  createTenant: Tenant;
  createUser: GcpipUser;
  create_booking_tenant?: Maybe<Scalars['JSONObject']>;
  create_voucher_tenant?: Maybe<Scalars['JSONObject']>;
  deleteBalloon: Scalars['ID'];
  deleteBooking: Scalars['ID'];
  deleteCategory: Scalars['ID'];
  deleteCrew: Scalars['ID'];
  deleteCylinder: Scalars['ID'];
  deleteEmailTemplate: Scalars['ID'];
  deleteEvent: Scalars['ID'];
  deleteFlight: Scalars['ID'];
  deleteLocation: Scalars['ID'];
  deleteLog: Scalars['ID'];
  deleteMonthAvailabilityEvents?: Maybe<Scalars['ID']>;
  deletePassenger: Scalars['ID'];
  deleteQuestion: Scalars['ID'];
  deleteRegion: Scalars['ID'];
  deleteReleaseNote?: Maybe<Scalars['Boolean']>;
  deleteTag: Scalars['ID'];
  deleteUser: Scalars['ID'];
  deleteVoucher: Scalars['ID'];
  fetchHolidays?: Maybe<Scalars['PositiveInt']>;
  initializeTenant?: Maybe<Scalars['String']>;
  inviteBookingsToFlight?: Maybe<Scalars['NonNegativeInt']>;
  markBookingMessagesRead?: Maybe<Scalars['ID']>;
  markBookingPaid: Scalars['ID'];
  markCrewMessagesRead?: Maybe<Scalars['ID']>;
  markReleaseNoteAsRead?: Maybe<Scalars['NonNegativeInt']>;
  mergeBookings?: Maybe<Booking>;
  rejectBookingToMyFlight: Scalars['ID'];
  removeBookingFromFlight: Scalars['ID'];
  saveBalloon: Scalars['ID'];
  saveBooking: Scalars['ID'];
  saveBookingPayment: Booking;
  saveCategory: Scalars['ID'];
  saveCrew: Scalars['ID'];
  saveCrewAvailability?: Maybe<Scalars['ID']>;
  saveCylinder: Scalars['ID'];
  saveEmailTemplate?: Maybe<EmailSettingsTemplate>;
  saveEvent: Scalars['ID'];
  saveFlight: Scalars['ID'];
  saveFlightCrew: Flight;
  saveFlightLanding: Scalars['ID'];
  saveLanguageSetting: Scalars['ID'];
  saveLocation: Scalars['ID'];
  savePassenger: Scalars['ID'];
  savePassengerPayment?: Maybe<Passenger>;
  savePilotSignOff: Scalars['ID'];
  saveQuestionOrder: Scalars['ID'];
  saveQuestionText: Scalars['ID'];
  saveRegion: Scalars['ID'];
  saveSmsTemplate?: Maybe<SmsTemplate>;
  saveTag: Tag;
  saveUserViewState: User;
  saveVoucher: Scalars['ID'];
  sendBookingSms?: Maybe<Scalars['ID']>;
  sendCrewSms?: Maybe<Scalars['ID']>;
  sendFlightSms?: Maybe<Scalars['ID']>;
  sendLocationSms?: Maybe<Scalars['ID']>;
  sendPasswordResetEmail: Scalars['String'];
  setMonthAvailability?: Maybe<Scalars['ID']>;
  subscriptionSmsIgnoreWarning?: Maybe<Scalars['Boolean']>;
  toggleFlightVisibility: Flight;
  toggleQuestionActive: Scalars['ID'];
  toggleTenantOperatorSettingsBookingsShowPayments?: Maybe<TenantSetting>;
  updateBookingComments: Scalars['ID'];
  updateBookingPayment?: Maybe<Booking>;
  updateBookingPreferredFlights: Scalars['ID'];
  updateBookingTags: Scalars['ID'];
  updateFlightComments: Flight;
  updateFlightTags: Flight;
  updateMeteo?: Maybe<Scalars['Boolean']>;
  updateMyBooking?: Maybe<Scalars['ID']>;
  updateMyPassenger: Scalars['ID'];
  updatePassengerPaymentStatus?: Maybe<Passenger>;
  updateReleaseNote?: Maybe<ReleaseNote>;
  updateTenantLocationOptions?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsBackup?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsBookingsChildAge?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsCrew?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsDisabledPaymentTypes?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsFlights?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsMyFlight?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsSms?: Maybe<TenantSetting>;
  updateTenantOperatorSettingsTrajectoryProfile?: Maybe<TenantSetting>;
  update_booking_flight_tenant?: Maybe<Scalars['JSONObject']>;
};


export type MutationAddAndConfirmBookingsToFlightArgs = {
  bookings: Array<Maybe<Scalars['ID']>>;
  flightId: Scalars['ID'];
};


export type MutationAddBookingToMyFlightArgs = {
  myFlightId: Scalars['ID'];
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationAddBookingsToFlightArgs = {
  bookings: Array<Maybe<Scalars['ID']>>;
  flightId: Scalars['ID'];
};


export type MutationAddCrewToFlightFromCalendarArgs = {
  flightCrewInput?: Maybe<FlightCrewInput>;
  flightId: Scalars['ID'];
};


export type MutationAddEmailOutArgs = {
  emailInput: EmailInput;
};


export type MutationAddPassengersArgs = {
  bookingId: Scalars['ID'];
  count?: Maybe<Scalars['Int']>;
};


export type MutationAddReleaseNoteArgs = {
  releaseNote: InputReleaseNote;
};


export type MutationChangeFlightStatusArgs = {
  flightId: Scalars['ID'];
  flightStatus: FlightStatus;
};


export type MutationConfirmBookingToFlightArgs = {
  bookingId: Scalars['ID'];
};


export type MutationConfirmBookingToMyFlightArgs = {
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationCopyEmailTemplateArgs = {
  emailTemplateId: Scalars['ID'];
};


export type MutationCopyFlightArgs = {
  sourceFlightId: Scalars['ID'];
  destinationDates?: Maybe<Array<Maybe<Scalars['String']>>>;
  timezoneOffset?: Maybe<Scalars['Int']>;
};


export type MutationCopyWeatherArgs = {
  sourceFlightId: Scalars['ID'];
  destinationFlightIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};


export type MutationCreateBookingArgs = {
  flightId: Scalars['ID'];
};


export type MutationCreateBookingFromVoucherArgs = {
  voucherId: Scalars['ID'];
};


export type MutationCreateTenantArgs = {
  displayName: Scalars['String'];
};


export type MutationCreateUserArgs = {
  GcpipUserInput?: Maybe<GcpipUserInput>;
  tenantId?: Maybe<Scalars['ID']>;
};


export type MutationCreate_Booking_TenantArgs = {
  bookingInput?: Maybe<ApiBookingInput>;
};


export type MutationCreate_Voucher_TenantArgs = {
  voucherInput?: Maybe<ApiVoucherInput>;
};


export type MutationDeleteBalloonArgs = {
  balloonId: Scalars['ID'];
};


export type MutationDeleteBookingArgs = {
  bookingId: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type MutationDeleteCrewArgs = {
  crewId: Scalars['ID'];
};


export type MutationDeleteCylinderArgs = {
  cylinderId: Scalars['ID'];
};


export type MutationDeleteEmailTemplateArgs = {
  emailTemplateId: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  eventId: Scalars['ID'];
};


export type MutationDeleteFlightArgs = {
  flightId: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  locationId: Scalars['ID'];
};


export type MutationDeleteLogArgs = {
  bookingId: Scalars['ID'];
  logId: Scalars['ID'];
};


export type MutationDeleteMonthAvailabilityEventsArgs = {
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  crewId: Scalars['ID'];
};


export type MutationDeletePassengerArgs = {
  passengerId: Scalars['ID'];
  bookingId: Scalars['ID'];
};


export type MutationDeleteQuestionArgs = {
  questionId: Scalars['ID'];
};


export type MutationDeleteRegionArgs = {
  regionId: Scalars['ID'];
};


export type MutationDeleteReleaseNoteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTagArgs = {
  tagId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  uid: Scalars['String'];
  tenantId?: Maybe<Scalars['ID']>;
};


export type MutationDeleteVoucherArgs = {
  voucherId: Scalars['ID'];
};


export type MutationFetchHolidaysArgs = {
  year: Scalars['PositiveInt'];
  countryCode?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};


export type MutationInitializeTenantArgs = {
  tenantId: Scalars['ID'];
};


export type MutationInviteBookingsToFlightArgs = {
  bookings: Array<Maybe<Scalars['ID']>>;
  flightId: Scalars['ID'];
};


export type MutationMarkBookingMessagesReadArgs = {
  bookingId: Scalars['ID'];
};


export type MutationMarkBookingPaidArgs = {
  bookingId: Scalars['ID'];
};


export type MutationMarkCrewMessagesReadArgs = {
  crewId: Scalars['ID'];
};


export type MutationMarkReleaseNoteAsReadArgs = {
  releaseNoteIds: Array<Maybe<Scalars['ID']>>;
};


export type MutationMergeBookingsArgs = {
  bookingIdFrom: Scalars['ID'];
  bookingIdTo: Scalars['ID'];
};


export type MutationRejectBookingToMyFlightArgs = {
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationRemoveBookingFromFlightArgs = {
  bookingId: Scalars['ID'];
};


export type MutationSaveBalloonArgs = {
  balloonInput: BalloonInput;
  cylinders: Array<Maybe<Scalars['ID']>>;
  balloonId?: Maybe<Scalars['ID']>;
};


export type MutationSaveBookingArgs = {
  bookingInput: BookingInput;
  bookingId?: Maybe<Scalars['ID']>;
  locationInput?: Maybe<LocationInput>;
};


export type MutationSaveBookingPaymentArgs = {
  bookingId: Scalars['ID'];
  bookingPaymentInput: BookingPaymentInput;
};


export type MutationSaveCategoryArgs = {
  categoryInput: CategoryInput;
  categoryId?: Maybe<Scalars['ID']>;
};


export type MutationSaveCrewArgs = {
  crewInput: CrewInput;
  crewId?: Maybe<Scalars['ID']>;
};


export type MutationSaveCrewAvailabilityArgs = {
  crewAvailabilityInput: CrewAvailabilityInput;
  eventId?: Maybe<Scalars['ID']>;
};


export type MutationSaveCylinderArgs = {
  cylinderInput: CylinderInput;
  cylinderId?: Maybe<Scalars['ID']>;
};


export type MutationSaveEmailTemplateArgs = {
  emailTemplateInput?: Maybe<EmailTemplateInput>;
  emailTemplateId: Scalars['ID'];
};


export type MutationSaveEventArgs = {
  eventInput: EventInput;
  eventId?: Maybe<Scalars['ID']>;
};


export type MutationSaveFlightArgs = {
  flightInput: FlightInput;
  flightId?: Maybe<Scalars['ID']>;
};


export type MutationSaveFlightCrewArgs = {
  flightCrewInput?: Maybe<Array<Maybe<FlightCrewInput>>>;
  flightId: Scalars['ID'];
};


export type MutationSaveFlightLandingArgs = {
  landingInput: LandingInput;
  flightId: Scalars['ID'];
};


export type MutationSaveLanguageSettingArgs = {
  systemLanguage: Scalars['String'];
};


export type MutationSaveLocationArgs = {
  locationInput: LocationInput;
  regions: Array<Maybe<Scalars['ID']>>;
  locationId?: Maybe<Scalars['ID']>;
};


export type MutationSavePassengerArgs = {
  passengerInput: PassengerInput;
  passengerId?: Maybe<Scalars['ID']>;
  bookingId: Scalars['ID'];
};


export type MutationSavePassengerPaymentArgs = {
  bookingId: Scalars['ID'];
  passengerId: Scalars['ID'];
  passengerPaymentInput: PassengerPaymentInput;
};


export type MutationSavePilotSignOffArgs = {
  pilotSignOffInput: PilotSignOffInput;
  flightId: Scalars['ID'];
};


export type MutationSaveQuestionOrderArgs = {
  order: Scalars['NonNegativeInt'];
  questionId: Scalars['ID'];
};


export type MutationSaveQuestionTextArgs = {
  questionInput: QuestionInput;
  questionId: Scalars['ID'];
};


export type MutationSaveRegionArgs = {
  regionInput: RegionInput;
  regionId?: Maybe<Scalars['ID']>;
};


export type MutationSaveSmsTemplateArgs = {
  smsTemplateInput: SmsTemplateInput;
  smsTemplateId: Scalars['ID'];
};


export type MutationSaveTagArgs = {
  tagInput: TagInput;
  tagId?: Maybe<Scalars['ID']>;
};


export type MutationSaveUserViewStateArgs = {
  viewState: Scalars['JSONObject'];
};


export type MutationSaveVoucherArgs = {
  voucherInput: VoucherInput;
  voucherId?: Maybe<Scalars['ID']>;
};


export type MutationSendBookingSmsArgs = {
  bookingId: Scalars['ID'];
  smsInput: SmsInput;
};


export type MutationSendCrewSmsArgs = {
  crewId: Scalars['ID'];
  smsInput: SmsInput;
};


export type MutationSendFlightSmsArgs = {
  flightId: Scalars['ID'];
  smsInput: SmsInput;
};


export type MutationSendLocationSmsArgs = {
  locationId: Scalars['ID'];
  flightId: Scalars['ID'];
  smsInput: SmsInput;
};


export type MutationSendPasswordResetEmailArgs = {
  email: Scalars['ID'];
  tenantId?: Maybe<Scalars['ID']>;
};


export type MutationSetMonthAvailabilityArgs = {
  availability: CrewAvailability;
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  crewId: Scalars['ID'];
};


export type MutationSubscriptionSmsIgnoreWarningArgs = {
  ignore: Scalars['Boolean'];
};


export type MutationToggleFlightVisibilityArgs = {
  flightId: Scalars['ID'];
};


export type MutationToggleQuestionActiveArgs = {
  questionId: Scalars['ID'];
};


export type MutationUpdateBookingCommentsArgs = {
  bookingId: Scalars['ID'];
  comments?: Maybe<Scalars['String']>;
};


export type MutationUpdateBookingPaymentArgs = {
  bookingId: Scalars['ID'];
  paymentType: PaymentType;
};


export type MutationUpdateBookingPreferredFlightsArgs = {
  bookingId: Scalars['ID'];
  preferredFlights?: Maybe<Array<Maybe<PreferredFlightInput>>>;
};


export type MutationUpdateBookingTagsArgs = {
  bookingId: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateFlightCommentsArgs = {
  flightId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationUpdateFlightTagsArgs = {
  flightId: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateMeteoArgs = {
  locationId: Scalars['ID'];
  flightId: Scalars['ID'];
};


export type MutationUpdateMyBookingArgs = {
  myBookingInput: MyBookingInput;
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationUpdateMyPassengerArgs = {
  myPassengerInput: MyPassengerInput;
  myPassengerId: Scalars['ID'];
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
};


export type MutationUpdatePassengerPaymentStatusArgs = {
  bookingId: Scalars['ID'];
  passengerId: Scalars['ID'];
  paymentStatus: PaymentStatus;
};


export type MutationUpdateReleaseNoteArgs = {
  id: Scalars['ID'];
  releaseNote: InputReleaseNote;
};


export type MutationUpdateTenantLocationOptionsArgs = {
  options: InputLocationOptions;
};


export type MutationUpdateTenantOperatorSettingsBackupArgs = {
  settings: InputOperatorSettingsBackup;
};


export type MutationUpdateTenantOperatorSettingsBookingsChildAgeArgs = {
  childAge?: Maybe<Scalars['NonNegativeInt']>;
};


export type MutationUpdateTenantOperatorSettingsCrewArgs = {
  settings: InputOperatorSettingsCrew;
};


export type MutationUpdateTenantOperatorSettingsDisabledPaymentTypesArgs = {
  disabledPaymentTypes: Array<Maybe<Scalars['String']>>;
};


export type MutationUpdateTenantOperatorSettingsFlightsArgs = {
  settings: InputOperatorSettingsFlights;
};


export type MutationUpdateTenantOperatorSettingsMyFlightArgs = {
  settings: InputOperatorSettingsMyFlight;
};


export type MutationUpdateTenantOperatorSettingsSmsArgs = {
  settings: InputOperatorSettingsSms;
};


export type MutationUpdateTenantOperatorSettingsTrajectoryProfileArgs = {
  settings: Array<Maybe<InputOperatorSettingsTrajectoryProfile>>;
};


export type MutationUpdate_Booking_Flight_TenantArgs = {
  bookingId?: Maybe<Scalars['ID']>;
  flightId?: Maybe<Scalars['ID']>;
};

export type MyBookingInput = {
  contactEmail: Scalars['String'];
  contactLanguage?: Maybe<ContactLanguage>;
  contactName: Scalars['String'];
  contactPhone: Scalars['PhoneNumber'];
};

export type MyPassengerInput = {
  child?: Maybe<Scalars['Boolean']>;
  disability?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  luggage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['PositiveInt']>;
  age?: Maybe<Scalars['PositiveInt']>;
  transport?: Maybe<Scalars['Boolean']>;
};

export type Myflight = {
  __typename?: 'Myflight';
  booking?: Maybe<Booking>;
  flights?: Maybe<Array<Maybe<Flight>>>;
  regions?: Maybe<Array<Maybe<Region>>>;
  tenantSetting?: Maybe<TenantSetting>;
  messagesCollection?: Maybe<Scalars['String']>;
};

export type MyflightBooking = {
  __typename?: 'MyflightBooking';
  booking?: Maybe<Booking>;
  regions?: Maybe<Array<Maybe<Region>>>;
  tenantSetting?: Maybe<TenantSetting>;
  messagesCollection?: Maybe<Scalars['String']>;
};

export enum MyflightFlights {
  All = 'ALL',
  Regional = 'REGIONAL',
  None = 'NONE'
}








export type OperatorSettings = {
  __typename?: 'OperatorSettings';
  backup?: Maybe<OperatorSettingsBackup>;
  bookings?: Maybe<OperatorSettingsBookings>;
  flights?: Maybe<OperatorSettingsFlights>;
  crew?: Maybe<OperatorSettingsCrew>;
  currency?: Maybe<TenantCurrency>;
  manifest?: Maybe<OperatorSettingsManifest>;
  myflight?: Maybe<OperatorSettingsMyflight>;
  releasenotes?: Maybe<Array<Maybe<Scalars['String']>>>;
  sms?: Maybe<OperatorSettingsSms>;
  timezone?: Maybe<Scalars['String']>;
  trajectoryProfile?: Maybe<Array<Maybe<TrajectoryProfile>>>;
};

export type OperatorSettingsBackup = {
  __typename?: 'OperatorSettingsBackup';
  active?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
};

export type OperatorSettingsBookings = {
  __typename?: 'OperatorSettingsBookings';
  showPayments?: Maybe<Scalars['Boolean']>;
  disabledPaymentTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
  childAge?: Maybe<Scalars['NonNegativeInt']>;
};

export type OperatorSettingsCrew = {
  __typename?: 'OperatorSettingsCrew';
  shareBookings?: Maybe<Scalars['Boolean']>;
  allowFlightSelection?: Maybe<Scalars['Boolean']>;
};

export type OperatorSettingsFlights = {
  __typename?: 'OperatorSettingsFlights';
  sunriseOffset?: Maybe<Scalars['NonNegativeInt']>;
  sunsetOffset?: Maybe<Scalars['NonNegativeInt']>;
  meetingTimeOffset?: Maybe<Scalars['NonNegativeInt']>;
};

export type OperatorSettingsManifest = {
  __typename?: 'OperatorSettingsManifest';
  pilotDeclaration?: Maybe<Array<Maybe<Scalars['String']>>>;
  footer?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OperatorSettingsMyflight = {
  __typename?: 'OperatorSettingsMyflight';
  autoConfirm?: Maybe<Scalars['Boolean']>;
  flights?: Maybe<MyflightFlights>;
  endOfSeason?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OperatorSettingsSms = {
  __typename?: 'OperatorSettingsSms';
  footerEnabled?: Maybe<Scalars['Boolean']>;
};

export enum OrderByBooking {
  ContactName = 'contactName',
  BookingDate = 'bookingDate',
  PreferredDate = 'preferredDate'
}

export enum OrderByFlight {
  Status = 'status',
  Date = 'date'
}

export enum OrderByVoucher {
  ContactName = 'contactName',
  VoucherReference = 'voucherReference',
  IssueDate = 'issueDate'
}

export enum OrderDirection {
  Desc = 'desc',
  Asc = 'asc'
}

export type Passenger = {
  __typename?: 'Passenger';
  accompany?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  child?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  disability?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  luggage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  postalCode?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['DateTime']>;
  signature?: Maybe<Scalars['String']>;
  totalWeight?: Maybe<Scalars['PositiveInt']>;
  voucher?: Maybe<Voucher>;
  voucherNumber?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['PositiveInt']>;
  age?: Maybe<Scalars['PositiveInt']>;
  transport?: Maybe<Scalars['Boolean']>;
};

export type PassengerCounters = {
  __typename?: 'PassengerCounters';
  id?: Maybe<Scalars['ID']>;
  childCount?: Maybe<Scalars['NonNegativeInt']>;
  disabilityCount?: Maybe<Scalars['NonNegativeInt']>;
  luggageCount?: Maybe<Scalars['NonNegativeInt']>;
  transportCount?: Maybe<Scalars['NonNegativeInt']>;
  openAmount?: Maybe<Scalars['NonNegativeInt']>;
  paidAmount?: Maybe<Scalars['NonNegativeInt']>;
  passengerCount?: Maybe<Scalars['NonNegativeInt']>;
  totalAmount?: Maybe<Scalars['NonNegativeInt']>;
  totalWeight?: Maybe<Scalars['NonNegativeInt']>;
  unpaidPassengerCount?: Maybe<Scalars['NonNegativeInt']>;
};

export type PassengerData = {
  __typename?: 'PassengerData';
  freeSpots?: Maybe<Scalars['NonNegativeInt']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  occupancy?: Maybe<Scalars['NonNegativeInt']>;
  passengersCount?: Maybe<Scalars['NonNegativeInt']>;
  passengersCountAdded?: Maybe<Scalars['NonNegativeInt']>;
  passengersCountConfirmed?: Maybe<Scalars['NonNegativeInt']>;
  passengersCountInvited?: Maybe<Scalars['NonNegativeInt']>;
  passengersWeight?: Maybe<Scalars['NonNegativeInt']>;
};

export type PassengerInput = {
  accompany?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['ID']>;
  child: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  disability?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  luggage?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  voucherId?: Maybe<Scalars['ID']>;
  voucherNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['PositiveInt']>;
  age?: Maybe<Scalars['PositiveInt']>;
  transport?: Maybe<Scalars['Boolean']>;
};

export type PassengerPaymentInput = {
  category?: Maybe<Scalars['ID']>;
  paymentReference?: Maybe<Scalars['String']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentAmount?: Maybe<Scalars['Float']>;
  child?: Maybe<Scalars['Boolean']>;
  voucherId?: Maybe<Scalars['ID']>;
  voucherNumber?: Maybe<Scalars['String']>;
};

export enum PaymentStatus {
  Open = 'OPEN',
  Paid = 'PAID',
  Refunded = 'REFUNDED'
}

export enum PaymentType {
  Nopa = 'NOPA',
  Invo = 'INVO',
  Cash = 'CASH',
  Coup = 'COUP',
  Vouc = 'VOUC',
  Wire = 'WIRE',
  Banc = 'BANC',
  Cred = 'CRED',
  Onli = 'ONLI'
}


export enum PilotLicense {
  Com = 'COM',
  Nco = 'NCO',
  Nol = 'NOL'
}

export type PilotSignOffInput = {
  pilotComments?: Maybe<Scalars['String']>;
  pilotSignature?: Maybe<Scalars['String']>;
};

export type Pluscode = {
  __typename?: 'Pluscode';
  compound_code?: Maybe<Scalars['String']>;
  global_code?: Maybe<Scalars['String']>;
};





export type PreferredDateInput = {
  date?: Maybe<Scalars['String']>;
  period?: Maybe<FlightPeriod>;
};

export type PreferredFlight = {
  __typename?: 'PreferredFlight';
  date?: Maybe<Scalars['String']>;
  period?: Maybe<FlightPeriod>;
};

export type PreferredFlightInput = {
  date?: Maybe<Scalars['String']>;
  period?: Maybe<FlightPeriod>;
};

export type Query = {
  __typename?: 'Query';
  allReleaseNotes?: Maybe<Array<Maybe<ReleaseNote>>>;
  balloon: Balloon;
  balloonWeight: BalloonWeight;
  balloons: Array<Maybe<Balloon>>;
  booking?: Maybe<Booking>;
  bookingConversations: Array<Maybe<Booking>>;
  bookingCounters?: Maybe<BookingCounters>;
  bookingsPreferredDate: Array<Maybe<Booking>>;
  bookingsToConfirm?: Maybe<Scalars['NonNegativeInt']>;
  calendar: Calendar;
  categories: Array<Maybe<Category>>;
  category: Category;
  copyProductionToDevelopment: Scalars['NonNegativeInt'];
  countries: Array<Maybe<Country>>;
  crew: Crew;
  crewCalendar: Array<Maybe<CrewCalendarItem>>;
  crewConversations: Array<Maybe<Crew>>;
  crews: Array<Maybe<Crew>>;
  crewsPerFlight: Array<Maybe<CrewPerFlight>>;
  cylinder: Cylinder;
  cylinders: Array<Maybe<Cylinder>>;
  dashboardBookings: Array<Maybe<Booking>>;
  defaultCategory: Category;
  events: Array<Maybe<Event>>;
  expiredVouchers: Array<Maybe<Voucher>>;
  fetchSkeyesForecast?: Maybe<FlightSkeyesForecast>;
  firstBookingWithFlight?: Maybe<Array<Maybe<Booking>>>;
  flight: Flight;
  flightCounters?: Maybe<FlightCounters>;
  flightPeriods?: Maybe<FlightPeriods>;
  flightsOfDashboard: Array<Maybe<Flight>>;
  flightsOfDate: Array<Maybe<Flight>>;
  flightsOfDateCrew: Array<Maybe<Flight>>;
  geocode: Array<Maybe<GeocodeResult>>;
  getAllEmailTemplates: Array<Maybe<EmailSettingsTemplate>>;
  getAllSmsTemplates: Array<Maybe<SmsTemplate>>;
  getAllTags: Array<Maybe<Tag>>;
  getBookingsIS: Array<Maybe<Booking>>;
  getEmailTemplate: EmailSettingsTemplate;
  getEmailTemplateByID: EmailSettingsTemplate;
  getEmailTemplateByLanguage: EmailTemplateByLanguage;
  getEmailTemplates: Array<Maybe<EmailSettingsTemplate>>;
  getFlightsList: Array<Maybe<Flight>>;
  getHistoricalFlightsList: Array<Maybe<Flight>>;
  getSkeyesForecast?: Maybe<SkeyesForecast>;
  getSmsTemplates: Array<Maybe<SmsTemplate>>;
  getTag: Tag;
  getTagsOfType: Array<Maybe<Tag>>;
  getTenantByDisplayName: Tenant;
  getTenants: Array<Maybe<Tenant>>;
  getUser?: Maybe<GcpipUser>;
  getUsers: Array<Maybe<GcpipUser>>;
  get_booking_tenant?: Maybe<Scalars['JSONObject']>;
  get_crew_ics?: Maybe<Scalars['JSON']>;
  get_flights_tenant?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  historicalBookings: Array<Maybe<Booking>>;
  location: Location;
  locations: Array<Maybe<Location>>;
  manufacturers?: Maybe<Manufacturers>;
  myflight?: Maybe<Myflight>;
  myflightBooking?: Maybe<MyflightBooking>;
  myflightFlights?: Maybe<Array<Maybe<Flight>>>;
  myflightLegacy?: Maybe<Myflight>;
  openVouchers: Array<Maybe<Voucher>>;
  parseEmailTemplate: EmailTemplateByLanguage;
  passengerCounters?: Maybe<PassengerCounters>;
  passengerRegionDistribution?: Maybe<Chart>;
  pilotsPerFlight: Array<Maybe<CrewPerFlight>>;
  questions: Array<Maybe<Question>>;
  receive?: Maybe<Scalars['String']>;
  region: Region;
  regions: Array<Maybe<Region>>;
  releaseNote?: Maybe<ReleaseNote>;
  releaseNotes?: Maybe<Array<Maybe<ReleaseNote>>>;
  releaseNotesCount?: Maybe<Scalars['NonNegativeInt']>;
  reports?: Maybe<Reports>;
  reverseGeocode?: Maybe<Scalars['String']>;
  scheduledFlights: Array<Maybe<Flight>>;
  serverStatus?: Maybe<ServerStatus>;
  smsByBooking?: Maybe<Array<Maybe<Sms>>>;
  smsByCrew?: Maybe<Array<Maybe<Sms>>>;
  smsByFlight?: Maybe<Array<Maybe<Sms>>>;
  smsByPhone?: Maybe<Array<Maybe<Sms>>>;
  sunriseSunset: SunriseSunset;
  tenant?: Maybe<TenantInfo>;
  tenantSetting?: Maybe<TenantSetting>;
  unreadSms: Array<Maybe<Sms>>;
  upFlights?: Maybe<Array<Flight>>;
  upUrl?: Maybe<Scalars['String']>;
  upcomingBookings: Array<Maybe<Booking>>;
  upcomingFlights: Array<Maybe<Flight>>;
  usedVouchers: Array<Maybe<Voucher>>;
  voucher?: Maybe<Voucher>;
  voucherCounters?: Maybe<VoucherCounters>;
  vouchers: Array<Maybe<Voucher>>;
};


export type QueryBalloonArgs = {
  balloonId: Scalars['ID'];
};


export type QueryBalloonWeightArgs = {
  balloonId: Scalars['ID'];
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryBookingConversationsArgs = {
  offset?: Maybe<Scalars['NonNegativeInt']>;
};


export type QueryBookingsPreferredDateArgs = {
  preferredDateInput?: Maybe<PreferredDateInput>;
};


export type QueryCalendarArgs = {
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
};


export type QueryCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type QueryCopyProductionToDevelopmentArgs = {
  tenantId?: Maybe<Scalars['String']>;
  copyUsers?: Maybe<Scalars['Boolean']>;
  copyHistory?: Maybe<Scalars['Boolean']>;
};


export type QueryCrewArgs = {
  crewId: Scalars['ID'];
};


export type QueryCrewCalendarArgs = {
  year: Scalars['PositiveInt'];
  month: Scalars['PositiveInt'];
  userId?: Maybe<Scalars['String']>;
};


export type QueryCrewConversationsArgs = {
  offset?: Maybe<Scalars['NonNegativeInt']>;
};


export type QueryCrewsPerFlightArgs = {
  flightId: Scalars['ID'];
};


export type QueryCylinderArgs = {
  cylinderId: Scalars['ID'];
};


export type QueryExpiredVouchersArgs = {
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
};


export type QueryFetchSkeyesForecastArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
  flightId: Scalars['ID'];
};


export type QueryFlightArgs = {
  flightId: Scalars['ID'];
};


export type QueryFlightsOfDateArgs = {
  date: Scalars['String'];
};


export type QueryFlightsOfDateCrewArgs = {
  date: Scalars['String'];
};


export type QueryGeocodeArgs = {
  address: Scalars['String'];
};


export type QueryGetBookingsIsArgs = {
  showUpcoming?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  bookingFilter?: Maybe<BookingFilter>;
  flightId?: Maybe<Scalars['ID']>;
  flightOnlyMatching?: Maybe<Scalars['Boolean']>;
  endAt?: Maybe<Scalars['Boolean']>;
};


export type QueryGetEmailTemplateArgs = {
  type: EmailTemplateType;
};


export type QueryGetEmailTemplateByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetEmailTemplateByLanguageArgs = {
  type: EmailTemplateType;
  lang?: Maybe<ContactLanguage>;
};


export type QueryGetEmailTemplatesArgs = {
  type: EmailTemplateType;
};


export type QueryGetFlightsListArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterRange?: Maybe<FilterRangeInput>;
};


export type QueryGetHistoricalFlightsListArgs = {
  cursor?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  filterRange?: Maybe<FilterRangeInput>;
};


export type QueryGetSkeyesForecastArgs = {
  slot: Scalars['String'];
};


export type QueryGetSmsTemplatesArgs = {
  type: Scalars['String'];
};


export type QueryGetTagArgs = {
  tagId: Scalars['ID'];
};


export type QueryGetTagsOfTypeArgs = {
  tagType?: Maybe<TagType>;
};


export type QueryGetTenantByDisplayNameArgs = {
  displayName: Scalars['String'];
};


export type QueryGetUsersArgs = {
  tenantId: Scalars['String'];
};


export type QueryGet_Booking_TenantArgs = {
  bookingId: Scalars['ID'];
};


export type QueryHistoricalBookingsArgs = {
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  cursor?: Maybe<Scalars['String']>;
  bookingFilter?: Maybe<BookingFilter>;
};


export type QueryLocationArgs = {
  locationId: Scalars['ID'];
};


export type QueryMyflightArgs = {
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
};


export type QueryMyflightBookingArgs = {
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
};


export type QueryMyflightFlightsArgs = {
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
};


export type QueryMyflightLegacyArgs = {
  legacyBooking: Scalars['String'];
};


export type QueryOpenVouchersArgs = {
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
};


export type QueryParseEmailTemplateArgs = {
  emailBody: Scalars['String'];
  language: Scalars['String'];
  flightPeriod: Scalars['String'];
};


export type QueryPassengerCountersArgs = {
  passengers: Array<Maybe<PassengerInput>>;
};


export type QueryPilotsPerFlightArgs = {
  flightId: Scalars['ID'];
};


export type QueryQuestionsArgs = {
  active?: Maybe<Scalars['Boolean']>;
};


export type QueryReceiveArgs = {
  msisdn: Scalars['String'];
  text: Scalars['String'];
};


export type QueryRegionArgs = {
  regionId: Scalars['ID'];
};


export type QueryReleaseNoteArgs = {
  releaseNoteId: Scalars['ID'];
};


export type QueryReverseGeocodeArgs = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};


export type QueryScheduledFlightsArgs = {
  orderBy?: Maybe<OrderByFlight>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
};


export type QuerySmsByBookingArgs = {
  bookingId: Scalars['ID'];
};


export type QuerySmsByCrewArgs = {
  crewId: Scalars['ID'];
};


export type QuerySmsByFlightArgs = {
  flightId: Scalars['ID'];
};


export type QuerySmsByPhoneArgs = {
  phone?: Maybe<Scalars['String']>;
};


export type QuerySunriseSunsetArgs = {
  sunriseSunsetInput?: Maybe<SunriseSunsetInput>;
};


export type QueryUpUrlArgs = {
  bookingId: Scalars['ID'];
};


export type QueryUpcomingBookingsArgs = {
  orderBy?: Maybe<OrderByBooking>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Array<Maybe<Scalars['String']>>>;
  cursor?: Maybe<Scalars['String']>;
  bookingFilter?: Maybe<BookingFilter>;
  flightId?: Maybe<Scalars['ID']>;
  flightOnlyMatching?: Maybe<Scalars['Boolean']>;
};


export type QueryUpcomingFlightsArgs = {
  orderBy?: Maybe<OrderByFlight>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
};


export type QueryUsedVouchersArgs = {
  orderBy?: Maybe<OrderByVoucher>;
  orderDirection?: Maybe<OrderDirection>;
  limit?: Maybe<Scalars['NonNegativeInt']>;
  filter?: Maybe<Scalars['String']>;
  cursor?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
};


export type QueryVoucherArgs = {
  id: Scalars['ID'];
};

export type Question = {
  __typename?: 'Question';
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<QuestionTemplate>;
  content?: Maybe<QuestionContent>;
  id: Scalars['ID'];
  kind?: Maybe<QuestionKind>;
};

export type QuestionContent = {
  __typename?: 'QuestionContent';
  description?: Maybe<Array<Maybe<MlText>>>;
  order: Scalars['PositiveInt'];
  title: Array<Maybe<MlText>>;
};

export type QuestionContentInput = {
  description?: Maybe<Array<Maybe<MlTextInput>>>;
  order: Scalars['PositiveInt'];
  title: Array<Maybe<MlTextInput>>;
};

export type QuestionInput = {
  description?: Maybe<Scalars['String']>;
  lang?: Maybe<SystemLanguage>;
  title?: Maybe<Scalars['String']>;
};

export enum QuestionKind {
  Mandatory = 'mandatory',
  Optional = 'optional'
}

export enum QuestionTemplate {
  Accompany = 'accompany',
  Address = 'address',
  Child = 'child',
  Email = 'email',
  Luggage = 'luggage',
  Name = 'name',
  Pay = 'pay',
  Signature = 'signature',
  Weight = 'weight'
}



export type Region = {
  __typename?: 'Region';
  color?: Maybe<Scalars['String']>;
  defaultColor?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locations: Array<Maybe<Location>>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['NonNegativeInt']>;
  removed?: Maybe<Scalars['DateTime']>;
};

export type RegionInput = {
  color?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ReleaseNote = {
  __typename?: 'ReleaseNote';
  id?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  documentation?: Maybe<Scalars['String']>;
  oneliner?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
  tenants?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Reports = {
  __typename?: 'Reports';
  flights?: Maybe<Scalars['URL']>;
  bookings?: Maybe<Scalars['URL']>;
  bookingsOpen?: Maybe<Scalars['URL']>;
  vouchers?: Maybe<Scalars['URL']>;
  payments?: Maybe<Scalars['URL']>;
  sms?: Maybe<Scalars['URL']>;
};

export type ServerStatus = {
  __typename?: 'ServerStatus';
  maintenance?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  purpose?: Maybe<Scalars['String']>;
  expectedDuration?: Maybe<Scalars['NonNegativeInt']>;
};

export type SkeyesForecast = {
  __typename?: 'SkeyesForecast';
  id?: Maybe<Scalars['ID']>;
  datetime?: Maybe<Scalars['DateTime']>;
  full?: Maybe<Scalars['String']>;
  inversions?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['DateTime']>;
  outlook?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  slot?: Maybe<Scalars['String']>;
  weather?: Maybe<Scalars['String']>;
  winds?: Maybe<Scalars['String']>;
};

export type Sms = {
  __typename?: 'Sms';
  date?: Maybe<SmsDates>;
  direction?: Maybe<SmsDirection>;
  flight?: Maybe<Flight>;
  id: Scalars['ID'];
  messageIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  nexmo?: Maybe<Scalars['JSONObject']>;
  path?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  provider?: Maybe<SmsProvider>;
  raspberry?: Maybe<Scalars['JSONObject']>;
  text?: Maybe<Scalars['String']>;
  eventSummary?: Maybe<SmsEventSummary>;
};

export type SmsConversation = {
  __typename?: 'SmsConversation';
  bookingId?: Maybe<Scalars['ID']>;
  crewId?: Maybe<Scalars['ID']>;
  latest?: Maybe<SmsSummary>;
  type?: Maybe<SmsConversationType>;
  unread?: Maybe<Scalars['NonNegativeInt']>;
  booking?: Maybe<Booking>;
  crew?: Maybe<Crew>;
};

export enum SmsConversationType {
  Boo = 'BOO',
  Cre = 'CRE'
}

export type SmsDates = {
  __typename?: 'SmsDates';
  sms?: Maybe<Scalars['DateTime']>;
  read?: Maybe<Scalars['DateTime']>;
  processed?: Maybe<Scalars['DateTime']>;
};

export enum SmsDirection {
  In = 'IN',
  Out = 'OUT'
}

export type SmsEventSummary = {
  __typename?: 'SmsEventSummary';
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export type SmsInput = {
  text?: Maybe<Scalars['String']>;
  up?: Maybe<Scalars['Boolean']>;
  positiveReply?: Maybe<Scalars['String']>;
  negativeReply?: Maybe<Scalars['String']>;
  repeat?: Maybe<SmsRepeat>;
};

export type SmsLatest = {
  __typename?: 'SmsLatest';
  date?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  direction?: Maybe<SmsDirection>;
  status?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
};

export enum SmsProvider {
  Nexmo = 'NEXMO',
  Raspberry = 'RASPBERRY',
  Eimers = 'EIMERS'
}

export enum SmsRepeat {
  Single = 'SINGLE',
  Pax = 'PAX',
  All = 'ALL'
}

export type SmsSummary = {
  __typename?: 'SmsSummary';
  date?: Maybe<Scalars['DateTime']>;
  direction?: Maybe<SmsDirection>;
  messageId?: Maybe<Scalars['ID']>;
  summary?: Maybe<Scalars['String']>;
};

export type SmsTemplate = {
  __typename?: 'SmsTemplate';
  id: Scalars['ID'];
  template: Template;
  type: Scalars['String'];
};

export type SmsTemplateInput = {
  template?: Maybe<TemplateInput>;
  type?: Maybe<Scalars['String']>;
};

export enum SmsType {
  PassengerInvitationSms = 'passengerInvitationSms',
  ReminderSms = 'reminderSms',
  PilotInvitationSms = 'pilotInvitationSms',
  CancelSms = 'cancelSms',
  CrewInvitationSms = 'crewInvitationSms',
  LocationContactSms = 'locationContactSms'
}

export type StatusCounter = {
  __typename?: 'StatusCounter';
  status?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['NonNegativeInt']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  bookingCountersUpdated?: Maybe<BookingCounters>;
  factuursturenClient?: Maybe<Scalars['NonNegativeInt']>;
  flightCountersUpdated?: Maybe<FlightCounters>;
  newSms?: Maybe<Array<Maybe<Sms>>>;
  plan?: Maybe<SubscriptionPlan>;
  sms?: Maybe<SubscriptionSms>;
  voucherCountersUpdated?: Maybe<VoucherCounters>;
};

export type SubscriptionPlan = {
  __typename?: 'SubscriptionPlan';
  name?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['NonNegativeInt']>;
  used?: Maybe<Scalars['NonNegativeInt']>;
  pctUsed?: Maybe<Scalars['NonNegativeInt']>;
  validUntil?: Maybe<Scalars['DateTime']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type SubscriptionSms = {
  __typename?: 'SubscriptionSMS';
  available?: Maybe<Scalars['NonNegativeInt']>;
  ignoreWarning?: Maybe<Scalars['Boolean']>;
  usedSMS?: Maybe<Array<Maybe<UsedSms>>>;
  pctUsed?: Maybe<Scalars['NonNegativeInt']>;
};

export type SunriseSunset = {
  __typename?: 'SunriseSunset';
  sunrise?: Maybe<Scalars['DateTime']>;
  sunset?: Maybe<Scalars['DateTime']>;
  morningFlight?: Maybe<Scalars['String']>;
  eveningFlight?: Maybe<Scalars['String']>;
};

export type SunriseSunsetInput = {
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  flightDate?: Maybe<Scalars['DateTime']>;
  timezoneOffset?: Maybe<Scalars['Int']>;
};

export enum SystemLanguage {
  Nl = 'nl',
  Du = 'du',
  Fr = 'fr',
  En = 'en',
  Cs = 'cs',
  De = 'de',
  Es = 'es',
  It = 'it',
  Lv = 'lv',
  Pt = 'pt'
}

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  tag?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  type?: Maybe<TagType>;
  enabled?: Maybe<Scalars['Boolean']>;
  upcoming?: Maybe<Scalars['PositiveInt']>;
  history?: Maybe<Scalars['PositiveInt']>;
  locked?: Maybe<Scalars['Boolean']>;
  dashboard?: Maybe<Scalars['Boolean']>;
};

export type TagData = {
  __typename?: 'TagData';
  id: Scalars['ID'];
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type TagInput = {
  tag?: Maybe<Scalars['String']>;
  backgroundColor?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  type?: Maybe<TagType>;
  enabled?: Maybe<Scalars['Boolean']>;
  dashboard?: Maybe<Scalars['Boolean']>;
};

export enum TagType {
  Booking = 'booking',
  Flight = 'flight'
}

export type Template = {
  __typename?: 'Template';
  en: Scalars['String'];
  fr: Scalars['String'];
  nl: Scalars['String'];
  de: Scalars['String'];
};

export type TemplateInput = {
  en: Scalars['String'];
  fr: Scalars['String'];
  nl: Scalars['String'];
  de: Scalars['String'];
};

export type Tenant = {
  __typename?: 'Tenant';
  tenantSetting?: Maybe<TenantSetting>;
  tenantId?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
};

export enum TenantCurrency {
  Eur = 'EUR',
  Chf = 'CHF'
}

export type TenantInfo = {
  __typename?: 'TenantInfo';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  colors?: Maybe<Array<Maybe<Scalars['String']>>>;
  logo?: Maybe<Scalars['String']>;
};

export type TenantSetting = {
  __typename?: 'TenantSetting';
  dashboardOptions?: Maybe<DashboardOptions>;
  locationOptions?: Maybe<LocationOptions>;
  logo?: Maybe<Scalars['String']>;
  icsId?: Maybe<Scalars['String']>;
  mailAccountant?: Maybe<EmailAddressObject>;
  mailFrom?: Maybe<EmailAddressObject>;
  mailReplyTo?: Maybe<EmailAddressObject>;
  name?: Maybe<Scalars['String']>;
  operatorSettings?: Maybe<OperatorSettings>;
  subscription?: Maybe<Subscription>;
  tenantId: Scalars['String'];
  trial?: Maybe<Scalars['Boolean']>;
  trialEnds?: Maybe<Scalars['DateTime']>;
  upEnabled?: Maybe<Scalars['Boolean']>;
};

export type Ticket = {
  __typename?: 'Ticket';
  passenger?: Maybe<Passenger>;
  booking?: Maybe<Booking>;
  flight?: Maybe<Flight>;
};



export type TrajectoryProfile = {
  __typename?: 'TrajectoryProfile';
  duration?: Maybe<Scalars['NonNegativeInt']>;
  level?: Maybe<Scalars['NonNegativeInt']>;
};





export type User = {
  __typename?: 'User';
  id?: Maybe<Scalars['ID']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
  language?: Maybe<SystemLanguage>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UserRole>;
  tenant?: Maybe<Scalars['String']>;
  userRef?: Maybe<Scalars['String']>;
  viewState?: Maybe<Scalars['JSONObject']>;
  crew?: Maybe<Crew>;
};

export enum UserRole {
  Own = 'OWN',
  Opr = 'OPR',
  Cre = 'CRE',
  Rep = 'REP',
  Noa = 'NOA',
  Met = 'MET',
  Ass = 'ASS'
}


export type Viewport = {
  __typename?: 'Viewport';
  northeast?: Maybe<Mapslocation>;
  southwest?: Maybe<Mapslocation>;
};

export enum VisibilityType {
  Pri = 'PRI',
  Pub = 'PUB'
}


export type Voucher = {
  __typename?: 'Voucher';
  adults?: Maybe<Scalars['PositiveInt']>;
  adultsUsed?: Maybe<Scalars['PositiveInt']>;
  adultsAvailable?: Maybe<Scalars['PositiveInt']>;
  children?: Maybe<Scalars['PositiveInt']>;
  childrenUsed?: Maybe<Scalars['PositiveInt']>;
  childrenAvailable?: Maybe<Scalars['PositiveInt']>;
  comments?: Maybe<Scalars['String']>;
  contactCity?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLanguage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['PhoneNumber']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  external?: Maybe<Scalars['Boolean']>;
  externalSource?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  issueDate?: Maybe<Scalars['DateTime']>;
  paymentAmount?: Maybe<Scalars['PositiveInt']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  status?: Maybe<Scalars['String']>;
  tickets?: Maybe<Array<Maybe<Ticket>>>;
  voucherReference?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['PhoneNumber']>;
  recipientLanguage?: Maybe<ContactLanguage>;
  recipientEmail?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<Scalars['String']>;
  recipientPostalCode?: Maybe<Scalars['String']>;
  recipientCity?: Maybe<Scalars['String']>;
  recipientCountry?: Maybe<Scalars['String']>;
};

export type VoucherCounters = {
  __typename?: 'VoucherCounters';
  statusCounters?: Maybe<VoucherStatusCounters>;
  voucherTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type VoucherInput = {
  adults?: Maybe<Scalars['PositiveInt']>;
  children?: Maybe<Scalars['PositiveInt']>;
  comments?: Maybe<Scalars['String']>;
  contactCity?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactLanguage?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['PhoneNumber']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  external?: Maybe<Scalars['Boolean']>;
  externalSource?: Maybe<Scalars['String']>;
  issueDate?: Maybe<Scalars['DateTime']>;
  paymentAmount?: Maybe<Scalars['PositiveInt']>;
  paymentDate?: Maybe<Scalars['DateTime']>;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentType?: Maybe<PaymentType>;
  voucherReference?: Maybe<Scalars['String']>;
  voucherType?: Maybe<Scalars['String']>;
  recipientName?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['PhoneNumber']>;
  recipientLanguage?: Maybe<ContactLanguage>;
  recipientEmail?: Maybe<Scalars['String']>;
  recipientAddress?: Maybe<Scalars['String']>;
  recipientPostalCode?: Maybe<Scalars['String']>;
  recipientCity?: Maybe<Scalars['String']>;
  recipientCountry?: Maybe<Scalars['String']>;
};

export enum VoucherStatus {
  Avai = 'AVAI',
  Used = 'USED',
  Expi = 'EXPI'
}

export type VoucherStatusCounters = {
  __typename?: 'VoucherStatusCounters';
  AVAI?: Maybe<Scalars['PositiveInt']>;
  USED?: Maybe<Scalars['PositiveInt']>;
  EXPI?: Maybe<Scalars['PositiveInt']>;
};

export type SlotForecast = {
  __typename?: 'slotForecast';
  datepart?: Maybe<Array<Maybe<DatePart>>>;
  daytime?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  flytime?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  lowCloudsData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  pictoCodeData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  precipitationData?: Maybe<Array<Maybe<Scalars['Float']>>>;
  pressureData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  sunrise?: Maybe<Scalars['DateTime']>;
  sunset?: Maybe<Scalars['DateTime']>;
  temperatureData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  time?: Maybe<Array<Maybe<Scalars['String']>>>;
  utc_timeoffset?: Maybe<Scalars['Int']>;
  visibilityData?: Maybe<Array<Maybe<Scalars['NonNegativeInt']>>>;
  windDirectionData?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_1000?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_200?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_250?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_300?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_350?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_400?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_450?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_500?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_550?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_600?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_650?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_700?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_750?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_800?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_925?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_950?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windDirectionData_975?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windGustData?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_1000?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_200?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_250?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_300?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_350?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_400?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_450?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_500?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_550?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_600?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_650?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_700?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_750?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_800?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_925?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_950?: Maybe<Array<Maybe<Scalars['Float']>>>;
  windSpeedData_975?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type UpdateResult = {
  __typename?: 'updateResult';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type UsedSms = {
  __typename?: 'usedSMS';
  year?: Maybe<Scalars['NonNegativeInt']>;
  month?: Maybe<Scalars['NonNegativeInt']>;
  used?: Maybe<Scalars['NonNegativeInt']>;
};

export type MyflightQueryVariables = Exact<{
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
}>;


export type MyflightQuery = (
  { __typename?: 'Query' }
  & { myflight?: Maybe<(
    { __typename?: 'Myflight' }
    & Pick<Myflight, 'messagesCollection'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'bookingCode' | 'contactName' | 'contactLanguage' | 'contactPhone' | 'contactEmail' | 'endDate' | 'daysExpired'>
      & { passengers: Array<Maybe<(
        { __typename?: 'Passenger' }
        & Pick<Passenger, 'age' | 'child' | 'disability' | 'email' | 'id' | 'luggage' | 'name' | 'weight'>
      )>>, region?: Maybe<(
        { __typename?: 'Region' }
        & Pick<Region, 'name'>
      )>, flight?: Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'date' | 'hour' | 'meetingPointSameAsTakeOff' | 'meetingPointAddress' | 'meetingPointLatitude' | 'meetingPointLongitude' | 'meetingPointURL' | 'status'>
        & { location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'address' | 'city' | 'description' | 'latitude' | 'longitude' | 'name'>
        )> }
      )> }
    )>, tenantSetting?: Maybe<(
      { __typename?: 'TenantSetting' }
      & Pick<TenantSetting, 'logo' | 'name' | 'tenantId'>
      & { operatorSettings?: Maybe<(
        { __typename?: 'OperatorSettings' }
        & { myflight?: Maybe<(
          { __typename?: 'OperatorSettingsMyflight' }
          & Pick<OperatorSettingsMyflight, 'endOfSeason' | 'flights' | 'logo' | 'colors'>
        )>, bookings?: Maybe<(
          { __typename?: 'OperatorSettingsBookings' }
          & Pick<OperatorSettingsBookings, 'childAge'>
        )> }
      )> }
    )>, regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'name'>
    )>>>, flights?: Maybe<Array<Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id' | 'date' | 'hour' | 'status'>
      & { passengerCounters: (
        { __typename?: 'FlightPassengerCounters' }
        & Pick<FlightPassengerCounters, 'freeSpots' | 'occupancy'>
      ), location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'address' | 'name'>
      )>, balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'capacity'>
      )> }
    )>>> }
  )> }
);

export type MyflightFlightsQueryVariables = Exact<{
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
}>;


export type MyflightFlightsQuery = (
  { __typename?: 'Query' }
  & { myflightFlights?: Maybe<Array<Maybe<(
    { __typename?: 'Flight' }
    & Pick<Flight, 'id' | 'date' | 'hour' | 'status'>
    & { passengerCounters: (
      { __typename?: 'FlightPassengerCounters' }
      & Pick<FlightPassengerCounters, 'freeSpots' | 'occupancy'>
    ), location?: Maybe<(
      { __typename?: 'Location' }
      & Pick<Location, 'address' | 'name'>
    )>, balloon?: Maybe<(
      { __typename?: 'Balloon' }
      & Pick<Balloon, 'capacity'>
    )> }
  )>>> }
);

export type MyflightBookingQueryVariables = Exact<{
  bookingReference?: Maybe<Scalars['String']>;
  bookingEmail?: Maybe<Scalars['String']>;
}>;


export type MyflightBookingQuery = (
  { __typename?: 'Query' }
  & { myflightBooking?: Maybe<(
    { __typename?: 'MyflightBooking' }
    & Pick<MyflightBooking, 'messagesCollection'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'bookingCode' | 'contactName' | 'contactLanguage' | 'contactPhone' | 'contactEmail' | 'endDate' | 'daysExpired'>
      & { passengers: Array<Maybe<(
        { __typename?: 'Passenger' }
        & Pick<Passenger, 'age' | 'child' | 'disability' | 'email' | 'id' | 'luggage' | 'name' | 'weight'>
      )>>, region?: Maybe<(
        { __typename?: 'Region' }
        & Pick<Region, 'name'>
      )>, flight?: Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'date' | 'hour' | 'meetingPointSameAsTakeOff' | 'meetingPointAddress' | 'meetingPointLatitude' | 'meetingPointLongitude' | 'meetingPointURL' | 'status'>
        & { location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'address' | 'city' | 'description' | 'latitude' | 'longitude' | 'name'>
        )> }
      )> }
    )>, tenantSetting?: Maybe<(
      { __typename?: 'TenantSetting' }
      & Pick<TenantSetting, 'logo' | 'name' | 'tenantId'>
      & { operatorSettings?: Maybe<(
        { __typename?: 'OperatorSettings' }
        & { myflight?: Maybe<(
          { __typename?: 'OperatorSettingsMyflight' }
          & Pick<OperatorSettingsMyflight, 'endOfSeason' | 'flights' | 'logo' | 'colors'>
        )>, bookings?: Maybe<(
          { __typename?: 'OperatorSettingsBookings' }
          & Pick<OperatorSettingsBookings, 'childAge'>
        )> }
      )> }
    )>, regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'name'>
    )>>> }
  )> }
);

export type MyflightLegacyQueryVariables = Exact<{
  legacyBooking: Scalars['String'];
}>;


export type MyflightLegacyQuery = (
  { __typename?: 'Query' }
  & { myflightLegacy?: Maybe<(
    { __typename?: 'Myflight' }
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'status' | 'contactName' | 'contactLanguage' | 'contactPhone' | 'contactEmail'>
      & { passengers: Array<Maybe<(
        { __typename?: 'Passenger' }
        & Pick<Passenger, 'age' | 'child' | 'disability' | 'email' | 'id' | 'luggage' | 'name' | 'weight'>
      )>>, flight?: Maybe<(
        { __typename?: 'Flight' }
        & Pick<Flight, 'date' | 'hour' | 'status'>
        & { location?: Maybe<(
          { __typename?: 'Location' }
          & Pick<Location, 'address' | 'city' | 'description' | 'latitude' | 'longitude' | 'name'>
        )> }
      )> }
    )>, tenantSetting?: Maybe<(
      { __typename?: 'TenantSetting' }
      & Pick<TenantSetting, 'logo' | 'name' | 'tenantId'>
    )>, regions?: Maybe<Array<Maybe<(
      { __typename?: 'Region' }
      & Pick<Region, 'name'>
    )>>>, flights?: Maybe<Array<Maybe<(
      { __typename?: 'Flight' }
      & Pick<Flight, 'id' | 'date' | 'hour' | 'status'>
      & { passengerCounters: (
        { __typename?: 'FlightPassengerCounters' }
        & Pick<FlightPassengerCounters, 'freeSpots' | 'occupancy'>
      ), location?: Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'address' | 'name'>
      )>, balloon?: Maybe<(
        { __typename?: 'Balloon' }
        & Pick<Balloon, 'capacity'>
      )> }
    )>>> }
  )> }
);

export type UpdateMyBookingMutationVariables = Exact<{
  myBookingInput: MyBookingInput;
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
}>;


export type UpdateMyBookingMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMyBooking'>
);

export type UpdateMyPassengerMutationVariables = Exact<{
  myPassengerInput: MyPassengerInput;
  myPassengerId: Scalars['ID'];
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
}>;


export type UpdateMyPassengerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateMyPassenger'>
);

export type AddBookingToMyFlightMutationVariables = Exact<{
  myFlightId: Scalars['ID'];
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
}>;


export type AddBookingToMyFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addBookingToMyFlight'>
);

export type ConfirmBookingToMyFlightMutationVariables = Exact<{
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
}>;


export type ConfirmBookingToMyFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'confirmBookingToMyFlight'>
);

export type RejectBookingToMyFlightMutationVariables = Exact<{
  myBookingId: Scalars['ID'];
  myTenantId: Scalars['ID'];
}>;


export type RejectBookingToMyFlightMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectBookingToMyFlight'>
);

export const MyflightDocument = gql`
    query myflight($bookingReference: String, $bookingEmail: String) {
  myflight(bookingReference: $bookingReference, bookingEmail: $bookingEmail) {
    booking {
      id
      status
      bookingCode
      contactName
      contactLanguage
      contactPhone
      contactEmail
      endDate
      daysExpired
      passengers {
        age
        child
        disability
        email
        id
        luggage
        name
        weight
      }
      region {
        name
      }
      flight {
        date
        hour
        location {
          address
          city
          description
          latitude
          longitude
          name
        }
        meetingPointSameAsTakeOff
        meetingPointAddress
        meetingPointLatitude
        meetingPointLongitude
        meetingPointURL
        status
      }
    }
    tenantSetting {
      logo
      name
      tenantId
      operatorSettings {
        myflight {
          endOfSeason
          flights
          logo
          colors
        }
        bookings {
          childAge
        }
      }
    }
    regions {
      name
    }
    flights {
      id
      date
      hour
      passengerCounters {
        freeSpots
        occupancy
      }
      location {
        address
        name
      }
      balloon {
        capacity
      }
      status
    }
    messagesCollection
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyflightGQL extends Apollo.Query<MyflightQuery, MyflightQueryVariables> {
    document = MyflightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyflightFlightsDocument = gql`
    query myflightFlights($bookingReference: String, $bookingEmail: String) {
  myflightFlights(
    bookingReference: $bookingReference
    bookingEmail: $bookingEmail
  ) {
    id
    date
    hour
    passengerCounters {
      freeSpots
      occupancy
    }
    location {
      address
      name
    }
    balloon {
      capacity
    }
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyflightFlightsGQL extends Apollo.Query<MyflightFlightsQuery, MyflightFlightsQueryVariables> {
    document = MyflightFlightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyflightBookingDocument = gql`
    query myflightBooking($bookingReference: String, $bookingEmail: String) {
  myflightBooking(
    bookingReference: $bookingReference
    bookingEmail: $bookingEmail
  ) {
    booking {
      id
      status
      bookingCode
      contactName
      contactLanguage
      contactPhone
      contactEmail
      endDate
      daysExpired
      passengers {
        age
        child
        disability
        email
        id
        luggage
        name
        weight
      }
      region {
        name
      }
      flight {
        date
        hour
        location {
          address
          city
          description
          latitude
          longitude
          name
        }
        meetingPointSameAsTakeOff
        meetingPointAddress
        meetingPointLatitude
        meetingPointLongitude
        meetingPointURL
        status
      }
    }
    tenantSetting {
      logo
      name
      tenantId
      operatorSettings {
        myflight {
          endOfSeason
          flights
          logo
          colors
        }
        bookings {
          childAge
        }
      }
    }
    regions {
      name
    }
    messagesCollection
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyflightBookingGQL extends Apollo.Query<MyflightBookingQuery, MyflightBookingQueryVariables> {
    document = MyflightBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MyflightLegacyDocument = gql`
    query myflightLegacy($legacyBooking: String!) {
  myflightLegacy(legacyBooking: $legacyBooking) {
    booking {
      id
      status
      contactName
      contactLanguage
      contactPhone
      contactEmail
      passengers {
        age
        child
        disability
        email
        id
        luggage
        name
        weight
      }
      flight {
        date
        hour
        location {
          address
          city
          description
          latitude
          longitude
          name
        }
        status
      }
    }
    tenantSetting {
      logo
      name
      tenantId
    }
    regions {
      name
    }
    flights {
      id
      date
      hour
      passengerCounters {
        freeSpots
        occupancy
      }
      location {
        address
        name
      }
      balloon {
        capacity
      }
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MyflightLegacyGQL extends Apollo.Query<MyflightLegacyQuery, MyflightLegacyQueryVariables> {
    document = MyflightLegacyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMyBookingDocument = gql`
    mutation updateMyBooking($myBookingInput: MyBookingInput!, $myBookingId: ID!, $myTenantId: ID!) {
  updateMyBooking(
    myBookingInput: $myBookingInput
    myBookingId: $myBookingId
    myTenantId: $myTenantId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMyBookingGQL extends Apollo.Mutation<UpdateMyBookingMutation, UpdateMyBookingMutationVariables> {
    document = UpdateMyBookingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMyPassengerDocument = gql`
    mutation updateMyPassenger($myPassengerInput: MyPassengerInput!, $myPassengerId: ID!, $myBookingId: ID!, $myTenantId: ID!) {
  updateMyPassenger(
    myPassengerInput: $myPassengerInput
    myPassengerId: $myPassengerId
    myBookingId: $myBookingId
    myTenantId: $myTenantId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMyPassengerGQL extends Apollo.Mutation<UpdateMyPassengerMutation, UpdateMyPassengerMutationVariables> {
    document = UpdateMyPassengerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddBookingToMyFlightDocument = gql`
    mutation addBookingToMyFlight($myFlightId: ID!, $myBookingId: ID!, $myTenantId: ID!) {
  addBookingToMyFlight(
    myFlightId: $myFlightId
    myBookingId: $myBookingId
    myTenantId: $myTenantId
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddBookingToMyFlightGQL extends Apollo.Mutation<AddBookingToMyFlightMutation, AddBookingToMyFlightMutationVariables> {
    document = AddBookingToMyFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmBookingToMyFlightDocument = gql`
    mutation confirmBookingToMyFlight($myBookingId: ID!, $myTenantId: ID!) {
  confirmBookingToMyFlight(myBookingId: $myBookingId, myTenantId: $myTenantId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmBookingToMyFlightGQL extends Apollo.Mutation<ConfirmBookingToMyFlightMutation, ConfirmBookingToMyFlightMutationVariables> {
    document = ConfirmBookingToMyFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RejectBookingToMyFlightDocument = gql`
    mutation rejectBookingToMyFlight($myBookingId: ID!, $myTenantId: ID!) {
  rejectBookingToMyFlight(myBookingId: $myBookingId, myTenantId: $myTenantId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RejectBookingToMyFlightGQL extends Apollo.Mutation<RejectBookingToMyFlightMutation, RejectBookingToMyFlightMutationVariables> {
    document = RejectBookingToMyFlightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }