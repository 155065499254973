<mat-card class="mat-card-container mat-elevation-z8">
	<mat-card-title class="mat-title mat-title-end">
		<span class="mat-title-span">{{ 'LOGINPAGE.title' | translate }}</span>
	</mat-card-title>
	<mat-divider></mat-divider>
	<mat-card-content class="mat-content-container">
		<form [formGroup]="loginForm" (submit)="submitForm()" fxLayout="column" fxLayoutGap="10px">
			<div >
				<mat-form-field class="full-width">
					<mat-label>{{ 'LOGINPAGE.reference' | translate }}</mat-label>
					<mat-icon  matPrefix>qr_code_2</mat-icon>
					<input matInput type="text" required formControlName="reference" />
					<mat-error *ngIf="loginForm.controls.reference.hasError('required')">{{ 'LOGINPAGE.required' | translate }}</mat-error>
					<mat-error *ngIf="!loginForm.controls.reference.hasError('required') && loginForm.controls.reference.hasError('invalidBookingCode')">{{ 'LOGINPAGE.invalidBookingCode' | translate }}</mat-error>
				</mat-form-field>
			</div>
			<div >
				<mat-form-field class="full-width">
					<mat-label>{{ 'LOGINPAGE.email' | translate }}</mat-label>
					<mat-icon matPrefix>email</mat-icon>
					<input matInput type="text" required formControlName="email"  />
					<mat-error *ngIf="loginForm.controls.email.errors?.required">{{ 'LOGINPAGE.required' | translate }}</mat-error>
					<mat-error *ngIf="loginForm.controls.email.errors?.email">{{ 'LOGnlINPAGE.noemail' | translate }}</mat-error>
				</mat-form-field>
			</div>
            <div fxLayout="row" fxLayoutAlign="space-between start">
                <button mat-raised-button type="submit" color="primary" [disabled]="!loginForm.valid">{{ 'LOGINPAGE.login' | translate }}</button>
                <mat-chip-list>
                    <mat-chip [selected]="langSelected === 'nl'" (click)="updateLang('nl')">nl</mat-chip>
                    <mat-chip [selected]="langSelected === 'fr'" (click)="updateLang('fr')">fr</mat-chip>
                    <mat-chip [selected]="langSelected === 'en'" (click)="updateLang('en')">en</mat-chip>
                    <mat-chip [selected]="langSelected === 'de'" (click)="updateLang('de')">de</mat-chip>
				</mat-chip-list>
            </div>
		</form>
	</mat-card-content>
</mat-card>
