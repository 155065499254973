import { AgmMap } from '@agm/core';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-location-card',
	templateUrl: './location-card.component.html',
	styleUrls: ['./location-card.component.css'],
})
export class LocationCardComponent implements OnInit {
	@Input() flight: any = null;

	mapTypeId: any;
	zoom = 14;

	constructor() {}

	ngOnInit(): void {
		this.mapTypeId = 'hybrid';
	}

	opengm(meetingPointSameAsTakeOff: boolean) {
		let url = '';
		if (meetingPointSameAsTakeOff) {
			url = `https://maps.google.com/?q=${this.flight?.location?.latitude},${this.flight?.location?.longitude}`;
		} else {
			url = this.flight?.meetingPointURL;
		}
		if (url !== '') window.open(url, '_blank');
	}
}
