<mat-card class="mat-card-container mat-elevation-z8" *ngIf="passengers">
    <mat-card-title class="mat-title mat-title-end">
        <span class="mat-title-span">{{ 'PASSENGERCARD.mypassengers' | translate }}</span>
        <button mat-icon-button (click)="navigatePassengerPage()" *ngIf="!flight || flight.status === 'SCH'">
            <mat-icon>edit</mat-icon>
        </button>
    </mat-card-title>
    <mat-divider></mat-divider>
    <mat-card-content class="mat-content-container">
        <div *ngIf="!validPassengers() && (!flight || flight.status === 'SCH')" class="info-explanation">
            <mat-icon class="icon-explanation">info</mat-icon>
            <span>{{ 'PASSENGERCARD.missing_explanation' | translate }}</span>
        </div>
        <div *ngFor="let passenger of passengers; let index = index" class="passenger-row" (click)="navigatePassengerPage()">
            <span *ngIf="passenger.name !== 'PAX'">{{index + 1}}. {{passenger.name}}</span>
            <span *ngIf="passenger.name === 'PAX'" class="italic">
                {{index + 1}}. {{passenger.name}}
            </span>
            <div *ngIf="!validPassenger(passenger)" class="italic missing-data">
                {{ 'PASSENGERCARD.please_fill_in_the_missing_data' | translate }}
                <mat-icon class="icon-notification">announcement</mat-icon>
            </div>
        </div>
    </mat-card-content>
</mat-card>