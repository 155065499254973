<mat-card class="mat-card-container mat-elevation-z8">
	<mat-card-title class="mat-title mat-title-end">
		<span class="mat-title-span">{{ 'ENDOFSEASON.title' | translate }}</span>
	</mat-card-title>
	<mat-divider></mat-divider>
	<mat-card-content class="mat-content-container">
		<div class="info-explanation">
			<div>
				<mat-icon class="icon-explanation">info</mat-icon>
				<span>{{ 'ENDOFSEASON.message' | translate }}</span>
			</div>
			<div>
				<mat-icon class="icon-explanation">info</mat-icon>
				<span>{{ 'ENDOFSEASON.message_fr' | translate }}</span>
			</div>
			<div>
				<mat-icon class="icon-explanation">info</mat-icon>
				<span>{{ 'ENDOFSEASON.message_en' | translate }}</span>
			</div>
			<div>
				<mat-icon class="icon-explanation">info</mat-icon>
				<span>{{ 'ENDOFSEASON.message_de' | translate }}</span>
			</div>
		</div>
	</mat-card-content>
</mat-card>
