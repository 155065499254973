import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular-ivy';

if (environment.production) {
	enableProdMode();
}

Sentry.init({
	dsn: 'https://9d6b140d6c77ffa9a2944ba6526a9f19@o66931.ingest.us.sentry.io/4507015448166400',
	integrations: [],
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
