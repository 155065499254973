import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingPageComponent } from './components/booking-page/booking-page.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { PassengersPageComponent } from './components/passengers-page/passengers-page.component';
import { FlightPickerPageComponent } from './components/flight-picker-page/flight-picker-page.component';
import { LoginComponent } from './components/login/login.component';
import { EndofseasonComponent } from './components/endofseason/endofseason.component';
import { EndofbookingComponent } from './components/endofbooking/endofbooking.component';
import { ChatPageComponent } from './components/chat-page/chat-page.component';

const routes: Routes = [
	{ path: '', component: MainPageComponent },
	{ path: 'booking', component: BookingPageComponent, children: [{ path: '**', component: BookingPageComponent }] },
	{ path: 'login', component: LoginComponent, children: [{ path: '**', component: LoginComponent }] },
	{ path: 'endofseason', component: EndofseasonComponent, children: [{ path: '**', component: EndofseasonComponent }] },
	{
		path: 'endofbooking',
		component: EndofbookingComponent,
		children: [{ path: '**', component: EndofbookingComponent }],
	},
	{
		path: 'passengers',
		component: PassengersPageComponent,
		children: [{ path: '**', component: PassengersPageComponent }],
	},
	{
		path: 'flights',
		component: FlightPickerPageComponent,
		children: [{ path: '**', component: FlightPickerPageComponent }],
  },
  {
    path: 'chat',
    component: ChatPageComponent
  },
	{ path: '**', component: MainPageComponent }, // If the route was not found, we redirect back to main screen
	// TODO - create a 404 page where the user can land when page was not found
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
