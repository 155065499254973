import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-flight-picker-card',
	templateUrl: './flight-picker-card.component.html',
	styleUrls: ['./flight-picker-card.component.css'],
})
export class FlightPickerCardComponent implements OnInit {
	@Input() booking: any = null;

	constructor() {}

	ngOnInit(): void {}
}
