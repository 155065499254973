<mat-card class="mat-card-container mat-elevation-z8" *ngIf="booking">
	<mat-card-title class="mat-title mat-title-end">
		<span class="mat-title-span">{{ 'BOOKINGCARD.mybooking' | translate }}</span>
		<a [routerLink]="['/booking']" *ngIf="!booking.flight || booking.flight?.status === 'SCH'">
			<mat-icon>edit</mat-icon>
		</a>
	</mat-card-title>
	<mat-divider></mat-divider>
	<mat-card-content class="mat-content-container">
		<form [formGroup]="bookingForm">
			<div fxLayout="row wrap" fxLayoutAlign="start center">
				<mat-form-field class="full-width">
					<mat-icon matPrefix>qr_code_2</mat-icon>
					<input matInput type="text" formControlName="bookingCode" readonly />
				</mat-form-field>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="start center">
				<mat-form-field class="full-width">
					<mat-icon matPrefix>account_box</mat-icon>
					<input matInput type="text" formControlName="contactName" readonly />
				</mat-form-field>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="start center">
				<mat-form-field class="full-width">
					<mat-icon matPrefix>email</mat-icon>
					<input matInput type="text" formControlName="contactEmail" readonly />
				</mat-form-field>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="start center">
				<mat-form-field class="full-width">
					<mat-icon matPrefix>phone</mat-icon>
					<input matInput type="text" formControlName="contactPhone" readonly />
				</mat-form-field>
			</div>
			<div fxLayout="row wrap" fxLayoutAlign="start center" *ngIf="operatorSettings.myflight.flights === 'REGIONAL'">
				<mat-form-field class="full-width">
					<mat-icon matPrefix>bubble_chart</mat-icon>
					<input matInput type="text" formControlName="region" readonly />
				</mat-form-field>
			</div>
		</form>
		<mat-grid-list cols="2" rowHeight="80px">
			<mat-grid-tile (click)="navigatePax()">
				<div fxLayout="column wrap" fxLayoutAlign="center center">
					<div fxLayout="row wrap" fxLayoutAlign="start center">
						<mat-icon [matBadge]="booking.passengers.length">groups</mat-icon>
					</div>
					<div fxLayout="row wrap" fxLayoutAlign="start center">
						<h6>{{ 'BOOKINGCARD.persons_on_board' | translate }}</h6>
					</div>
				</div>
			</mat-grid-tile>
			<mat-grid-tile>
				<div fxLayout="column wrap" fxLayoutAlign="center center" fxFlex="50">
					<div fxLayout="row wrap" fxLayoutAlign="start center">
						<mat-icon [matBadge]="children(booking)" [matBadgeHidden]="!children(booking)">escalator_warning</mat-icon>
					</div>
					<div fxLayout="row wrap" fxLayoutAlign="start center">
						<h6>{{ 'BOOKINGCARD.children' | translate }} < {{ operatorSettings?.bookings?.childAge ?? '12' }}y</h6>
					</div>
				</div>
			</mat-grid-tile>
			<mat-grid-tile>
				<div fxLayout="column wrap" fxLayoutAlign="center center" fxFlex="50">
					<div fxLayout="row wrap" fxLayoutAlign="start center">
						<mat-icon [matBadge]="disability(booking)" [matBadgeHidden]="!disability(booking)">local_hospital</mat-icon>
					</div>
					<div fxLayout="row wrap" fxLayoutAlign="start center">
						<h6>{{ 'BOOKINGCARD.assistance_needed' | translate }}</h6>
					</div>
				</div>
			</mat-grid-tile>
			<mat-grid-tile>
				<div fxLayout="column wrap" fxLayoutAlign="center center" fxFlex="50">
					<div fxLayout="row wrap" fxLayoutAlign="start center">
						<mat-icon [matBadge]="luggage(booking)" [matBadgeHidden]="!luggage(booking)">backpack</mat-icon>
					</div>
					<div fxLayout="row wrap" fxLayoutAlign="start center">
						<h6>{{ 'BOOKINGCARD.luggage' | translate }}</h6>
					</div>
				</div>
			</mat-grid-tile>
		</mat-grid-list>
	</mat-card-content>
</mat-card>
