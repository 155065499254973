import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import {
	HttpClientModule,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpClient,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AgmCoreModule } from '@agm/core';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationPipesModule } from './_pipes/application-pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialComponentsModule } from './material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';

import { NavbarComponent } from './components/navbar/navbar.component';
import { BottomNavbarComponent } from './components/bottom-navbar/bottom-navbar.component';
import { BookingPageComponent } from './components/booking-page/booking-page.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { PassengersPageComponent } from './components/passengers-page/passengers-page.component';
import { ChatPageComponent } from './components/chat-page/chat-page.component';
import { FlightCardComponent } from './components/flight-card/flight-card.component';
import { BookingCardComponent } from './components/booking-card/booking-card.component';
import { PassengerCardComponent } from './components/passenger-card/passenger-card.component';
import { LocationCardComponent } from './components/location-card/location-card.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FlightPickerPageComponent } from './components/flight-picker-page/flight-picker-page.component';
import { FlightPickerCardComponent } from './components/flight-picker-card/flight-picker-card.component';
import { GraphQLModule } from './graphql.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { EndofseasonComponent } from './components/endofseason/endofseason.component';
import { ChatCardComponent } from './components/chat-card/chat-card.component';
import { AngularFireModule } from '@angular/fire/compat';
import { EndofbookingComponent } from './components/endofbooking/endofbooking.component';

import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class QRAPIInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token: string = JSON.parse(localStorage.getItem('token') || '{}');
		req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
		req = req.clone({
			headers: req.headers.set('Content-Type', 'application/json'),
		});
		if (token && typeof token === 'string') {
			req = req.clone({
				headers: req.headers.set('Authorization', 'Token ' + token),
			});
		}

		return next.handle(req);
	}
}

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@NgModule({
	declarations: [
		AppComponent,
		NavbarComponent,
		BottomNavbarComponent,
		BookingPageComponent,
		MainPageComponent,
		PassengersPageComponent,
		ChatPageComponent,
		FlightCardComponent,
		BookingCardComponent,
		PassengerCardComponent,
		LocationCardComponent,
		ConfirmationDialogComponent,
		FlightPickerPageComponent,
		FlightPickerCardComponent,
		LoginComponent,
		EndofseasonComponent,
		EndofbookingComponent,
		ChatCardComponent,
	],
	imports: [
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyCGgTeegCXfUhPXWQEseRHR89sk9MI8erM',
		}),
		AppRoutingModule,
		ApplicationPipesModule,
		BrowserAnimationsModule,
		BrowserModule,
		FlexLayoutModule,
		HttpClientModule,
		MaterialComponentsModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule.forRoot({
			defaultLanguage: 'nl',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		GraphQLModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		AngularFireModule.initializeApp(environment.firebaseConfig),
	],
	providers: [
		{
			provide: ErrorHandler,
			useValue: Sentry.createErrorHandler({
				showDialog: false,
			}),
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
