<mat-card class="mat-card-container mat-elevation-z8">
    <mat-card-content class="mat-content-container">
        <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
            <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                <mat-icon>flight_takeoff</mat-icon>
                <span>{{ 'FLIGHTPICKERCARD.upcoming_flights_in_your_area' | translate | capitalize }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <a [routerLink]="['/flights']" mat-raised-button
                    class="btn">{{ 'FLIGHTPICKERCARD.Choose_a_flight' | translate }}</a>
            </div>
        </div>
    </mat-card-content>
</mat-card>