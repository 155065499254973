import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FlightStatus } from '../../../gen-types';
import { UrlService } from '../../_services/url.service';

@Component({
	selector: 'app-passengers-page',
	templateUrl: './passengers-page.component.html',
	styleUrls: ['./passengers-page.component.css'],
})
export class PassengersPageComponent implements OnInit {
	isReadOnly = true;
	panelOpenState = false;
	passengers: any[] = [];
	allForms: UntypedFormGroup[] = []; // Contains all forms, one for each passenger
	doneLoading: boolean = false;
	myTenantId: string = '';
	myBookingId: string = '';
	childAge = 12;
	constructor(private urlService: UrlService) {}

	ngOnInit(): void {
		this.urlService.myflight.subscribe((myflight) => {
			if (myflight) {
				const booking = myflight.booking;
				this.passengers = booking.passengers;
				this.myTenantId = myflight.tenantSetting.tenantId;
				this.myBookingId = booking.id;
				if (!booking?.flight || booking?.flight?.status === FlightStatus.Sch) {
					this.isReadOnly = false;
				}
				if (myflight?.tenantSetting?.operatorSettings?.bookings?.childAge) {
					this.childAge = myflight.tenantSetting.operatorSettings.bookings.childAge;
				}

				if (this.passengers) this.fillInFields();
			}
		});
	}

	// Using the currently saved booking, fills in all fields with known data
	fillInFields(): void {
		this.passengers.forEach(
			(passenger: { name: any; weight: any; child: boolean; disability: boolean; luggage: boolean; email: string }) => {
				let passengerForm = this.createFormGroup();
				passengerForm.patchValue(passenger);
				this.allForms.push(passengerForm);
			}
		);
		this.doneLoading = true;
	}

	createFormGroup() {
		let passengerForm = new UntypedFormGroup({
			name: new UntypedFormControl(''),
			age: new UntypedFormControl(''),
			weight: new UntypedFormControl(''),
			child: new UntypedFormControl({ value: '', disabled: this.isReadOnly }),
			luggage: new UntypedFormControl({ value: '', disabled: this.isReadOnly }),
			disability: new UntypedFormControl(''),
			email: new UntypedFormControl(''),
		});
		return passengerForm;
	}

	leftFocus(i: number) {
		if (!this.allForms[i].pristine) {
			let myPassengerInput = this.allForms[i].value;
			myPassengerInput.weight = Math.trunc(myPassengerInput.weight);
			this.urlService
				.updateMyPassenger(myPassengerInput, this.passengers[i].id, this.myBookingId, this.myTenantId)
				.subscribe(() => {
					this.allForms[i].markAsPristine();
				});
		}
	}
}
