<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center" class="header">
  <div>
    <img [src]="noimageUrl" width="48">
  </div>
  <div>
    <ng-content></ng-content>
  </div>
  <div>
    <button mat-icon-button (click)=share()  >
        <mat-icon >share</mat-icon>
    </button>
  </div>
</mat-toolbar>