import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../../_services/url.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
	selector: 'app-flight-picker-page',
	templateUrl: './flight-picker-page.component.html',
	styleUrls: ['./flight-picker-page.component.css'],
})
export class FlightPickerPageComponent implements OnInit {
	upcomingFlights: Array<any> = [];
	myBookingId: string = '';
	myTenantId: string = '';
	doneLoading: boolean = false;

	constructor(
		private dialog: MatDialog,
		private translate: TranslateService,
		private urlService: UrlService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.urlService.myflight.subscribe((myflight) => {
			if (myflight) {
				this.myTenantId = myflight.tenantSetting.tenantId;
				this.myBookingId = myflight.booking.id;
			}
		});
		this.urlService.getFlights().subscribe((result) => {
			if (result.myflightFlights) {
				this.upcomingFlights = result.myflightFlights;
				this.doneLoading = true;
			}
		});
	}

	openConfirmationPopup(e: any, flight: any) {
		e.stopPropagation();
		this.translate
			.get(['FLIGHTPICKERPAGE.confirmation_title', 'FLIGHTPICKERPAGE.confirmation_explanation'])
			.subscribe((trans) => {
				var confirmationData = {
					title: trans['FLIGHTPICKERPAGE.confirmation_title'],
					body: trans['FLIGHTPICKERPAGE.confirmation_explanation'],
				};
				var dialogRef = this.dialog.open(ConfirmationDialogComponent, {
					height: '330px',
					width: '100%',
					data: confirmationData,
				});
				const sub = dialogRef.componentInstance.onYes.subscribe(() => {
					this.requestFlight(flight);
				});
				dialogRef.afterClosed().subscribe(() => {
					sub.unsubscribe();
				});
			});
	}

	requestFlight(flight: any) {
		this.urlService
			.addBookingToMyFlight(flight.id, this.myBookingId, this.myTenantId)
			.subscribe(() => this.navigateBack());
	}

	getFormattedDate(date: any): string {
		return moment(date).format('ddd D MMM YYYY');
	}

	getHour(hour: any): string {
		return moment(hour).format('HH:mm');
	}

	navigateBack() {
		this.router.navigate(['']);
	}
}
