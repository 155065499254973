import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import moment from 'moment';
import { Observable, from } from 'rxjs';
import { groupBy, map, mergeMap, toArray } from 'rxjs/operators';

@Component({
	selector: 'app-chat-card',
	templateUrl: './chat-card.component.html',
	styleUrls: ['./chat-card.component.css'],
})
export class ChatCardComponent implements OnInit {
	@Input() messagesCollection = '';
	chats: Observable<any[]> = new Observable<any[]>();
	chats2: Observable<any[]> = new Observable<any[]>();

	constructor(private firestore: AngularFirestore) {}

	ngOnInit(): void {
		if (this.messagesCollection) {
			this.chats = this.firestore
				.collection(this.messagesCollection)
				.valueChanges()
				.pipe(
					map((messages: any[]) => {
						// Sort all messages by date
						messages.sort((a, b) => b.date.sms.toDate().getTime() - a.date.sms.toDate().getTime());

						let groups = [];
						let currentGroup = null;

						for (let message of messages) {
							if (
								!currentGroup ||
								message.direction !== currentGroup.direction ||
								currentGroup.date.sms.toDate().getTime() - message.date.sms.toDate().getTime() > 60 * 1000
							) {
								// Start a new group if there is no current group, or if this message's direction or time doesn't match the current group
								currentGroup = {
									direction: message.direction,
									date: message.date,
									text: message.text,
								};
								groups.push(currentGroup);
							} else {
								// Otherwise, append this message's text to the current group's text
								currentGroup.text = message.text + currentGroup.text;
							}
						}

						return groups;
					})
				);
		}
	}

	isDifferentDay(date1: Date, date2: Date): boolean {
		return (
			date1.getDate() !== date2.getDate() ||
			date1.getMonth() !== date2.getMonth() ||
			date1.getFullYear() !== date2.getFullYear()
		);
	}

	formatTime(date: Date): string {
		return moment(date).format('HH:mm');
	}
	formatDate(date: Date): string {
		const _date = moment(date);
		return _date.isSame(moment(), 'day') ? 'today' : _date.format('dd D/MM/YY');
	}
}
