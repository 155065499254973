<mat-card class="mat-card-container mat-elevation-z8">
	<mat-card-title class="mat-title mat-title-end">
		<span class="mat-title-span">{{ 'MESSAGES.title' | translate }}</span>
	</mat-card-title>
	<mat-divider></mat-divider>
	<mat-card-content class="mat-content-container">
		<div class="chat-container">
			<ng-container *ngIf="chats | async as chatsList">
				<!-- No messages yet -->
				<div *ngIf="chatsList.length === 0" class="no-messages">
					{{ 'MESSAGES.no_messages' | translate }}
				</div>
				<!-- Loop through messages -->
				<div *ngFor="let chat of chatsList; let i = index" class="message-wrapper">
					<!-- Show date indication if the date changes -->
					<div
						*ngIf="i > 0 && isDifferentDay(chat.date.sms.toDate(), chatsList[i - 1].date.sms.toDate())"
						class="date-indicator"
					>
						{{
							formatDate(chat.date.sms.toDate()) === 'today'
								? ('MESSAGES.today' | translate | capitalize)
								: formatDate(chat.date.sms.toDate())
						}}
					</div>
					<div *ngIf="i === 0" class="date-indicator">
						{{
							formatDate(chat.date.sms.toDate()) === 'today'
								? ('MESSAGES.today' | translate | capitalize)
								: formatDate(chat.date.sms.toDate())
						}}
					</div>
					<div
						[ngClass]="{
							incoming: chat.direction === 'IN',
							outgoing: chat.direction === 'OUT',
							'message-wrapper': true
						}"
					>
						<div class="message">
							<!-- Display message content -->
							<div>
								{{ chat.text }}
							</div>

							<!-- Display timestamp -->
							<div class="timestamp">
								{{ formatTime(chat.date.sms.toDate()) }}
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</mat-card-content>
</mat-card>
