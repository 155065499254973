import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, OnChanges {
	@Input() logoUrl = '';
	@Input() myflight: any;
	@Input() legacy = false;
	noimageUrl = '../../../assets/icons/upconsulting-balloon.png';

	constructor(private translate: TranslateService) {}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.myflight) {
			this.myflight = changes.myflight.currentValue;
			if (this.myflight.tenantSetting.operatorSettings.myflight.logo) {
				this.noimageUrl = this.myflight.tenantSetting.operatorSettings.myflight.logo;
			} else {
				this.switchLogo();
			}
		}
	}

	switchLogo() {
		// hard coded switch of logo per tenant for legacy
		const tenantId = this.myflight.tenantSetting.name;
		switch (tenantId) {
			case 'bertelsballooning':
				this.noimageUrl = '../../../assets/icons/logo-bertelsballooning.png';
				break;
			case 'madou':
				this.noimageUrl = '../../../assets/icons/logo-madou.png';
				break;
			case 'upballooning':
				this.noimageUrl = '../../../assets/icons/logo-upballooning.png';
				break;
			case 'montgolfiere':
				this.noimageUrl = '../../../assets/icons/logo-montgolfiere.png';
				break;
			case 'airevents':
				this.noimageUrl = '../../../assets/icons/logo-airevents.png';
				break;
			case 'ballonvarenzeeland':
				this.noimageUrl = '../../../assets/icons/logo-ballonvarenzeeland.png';
				break;
			case 'belair':
				this.noimageUrl = '../../../assets/icons/logo-belair.png';
				break;
			case 'filva':
				this.noimageUrl = '../../../assets/icons/logo-filva.png';
				break;
			case 'spildooren-ballooning':
				this.noimageUrl = '../../../assets/icons/logo-spildooren-ballooning.png';
				break;
			case 'qualityballooning':
				this.noimageUrl = '../../../assets/icons/logo-qualityballooning.png';
				break;
			case 'stepin':
				this.noimageUrl = '../../../assets/icons/logo-stepin.png';
				break;
			case 'mteballonvaarten':
				this.noimageUrl = '../../../assets/icons/logo-mteballonvaarten.png';
				break;
		}
	}

	share() {
		const param =
			'?r=' + localStorage.getItem('_UP_BOOKING_REFERENCE') + '&e=' + localStorage.getItem('_UP_BOOKING_EMAIL');
		if (navigator.share) {
			navigator
				.share({
					title: this.translate.instant('MAINPAGE.hot_air_balloon_flight'),
					text: this.translate.instant('MAINPAGE.check_this_out'),
					url: environment.baseUrl + param,
				})
				.then(() => console.log('Successful share'))
				.catch((error) => console.log('Error sharing', error));
		}
	}
}
