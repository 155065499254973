<mat-card class="mat-card-container mat-elevation-z8">
  <mat-card-title class="mat-title">
    <span class="mat-title-span">{{ 'LOCATIONCARD.flightlocation' | translate }}</span>
  </mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content class="mat-content-container">
    <div *ngIf="flight && flight?.status!=='SCH'">
      <div *ngIf="flight?.meetingPointSameAsTakeOff && flight?.location?.latitude && flight?.location?.latitude!==0">
        <agm-map style="height: 360px" [latitude]="flight?.location?.latitude" [longitude]="flight?.location?.longitude"
          [disableDefaultUI]="true" [mapTypeId]="mapTypeId" [zoom]="zoom">
          <agm-marker [latitude]="flight?.location?.latitude" [longitude]="flight?.location?.longitude"
            [label]="{text: flight?.location?.name, color: '#ffffff'}">
            <agm-info-window>{{ flight?.location?.name }}</agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
      <div *ngIf="!flight?.meetingPointSameAsTakeOff && flight?.meetingPointLatitude && flight?.meetingPointLongitude">
        <agm-map style="height: 360px" [latitude]="flight?.meetingPointLatitude" [longitude]="flight?.meetingPointLongitude"
          [disableDefaultUI]="true" [mapTypeId]="mapTypeId" [zoom]="zoom">
          <agm-marker [latitude]="flight?.meetingPointLatitude" [longitude]="flight?.meetingPointLongitude"
            [label]="{text: ('LOCATIONCARD.meeting_point' | translate), color: '#ffffff'}">
            <agm-info-window>{{ 'LOCATIONCARD.meeting_point' | translate }}</agm-info-window>
          </agm-marker>
        </agm-map>
      </div>
      <div>
        <div *ngIf="flight?.meetingPointSameAsTakeOff">
          <div fxLayout="row" fxLayoutAlign="start start">
            <h3>{{ flight?.location?.address }}, {{ flight?.location?.city }}</h3>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start">
            <h4>{{ flight?.location?.description }}</h4>
          </div>
        </div>
        <div *ngIf="!flight?.meetingPointSameAsTakeOff">
          <div fxLayout="row" fxLayoutAlign="start start">
            <h3>{{ 'LOCATIONCARD.meeting_point' | translate }}</h3>
          </div>
          <div fxLayout="row" fxLayoutAlign="start start">
            <h4>{{ flight?.meetingPointAddress }}</h4>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button mat-raised-button class="btn" (click)="opengm(flight?.meetingPointSameAsTakeOff)">{{ 'LOCATIONCARD.open_in_maps' | translate
            }}</button>
        </div>
      </div>
    </div>
    <div *ngIf="!flight || flight?.status==='SCH'">
      <p>{{ 'LOCATIONCARD.scheduled_flight_message' | translate }}</p>
    </div>
  </mat-card-content>
</mat-card>