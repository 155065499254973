import { Component, Input, OnInit } from '@angular/core';
import { UrlService } from 'src/app/_services/url.service';

@Component({
	selector: 'app-endofbooking',
	templateUrl: './endofbooking.component.html',
	styleUrls: ['./endofbooking.component.css'],
})
export class EndofbookingComponent implements OnInit {
	daysExpired: number = 0;
	constructor(private urlService: UrlService) {
		// get daysExpired from urlService
		this.urlService.myflight.subscribe((data) => {
			if (data) {
				this.daysExpired = data.booking.daysExpired;
				console.log(this.daysExpired);
			}
		});
	}

	ngOnInit(): void {}
}
