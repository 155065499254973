import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FlightStatus } from 'src/gen-types';

@Component({
  selector: 'app-passenger-card',
  templateUrl: './passenger-card.component.html',
  styleUrls: ['./passenger-card.component.css']
})
export class PassengerCardComponent implements OnInit {
  @Input() passengers: any[] = [];
  @Input() flight: any;
  showExplanation = false;


  constructor(private router: Router) { }

  ngOnInit(): void {
    this.showExplanation = this.passengers.findIndex(p => this.validPassenger(p)) === -1
  }

  validPassenger(passenger: any): boolean {
    let valid = true
    if (passenger.name === 'PAX') return false
    if (passenger.name === '') return false
    if (passenger.weight === 0) return false
    return valid
  }

  validPassengers(): boolean {
    return (this.passengers.findIndex(p => !this.validPassenger(p)) === -1)
  }

  navigatePassengerPage() {
    if (!this.flight || this.flight.status === FlightStatus.Sch) {
      this.router.navigate(['passengers'])
    }
  }

}
