<mat-card class="mat-card-container">
    <mat-card-title class="mat-title mat-title-end dialog-mat-title">
        <span class="mat-title-span dialog-mat-title-span">{{data.title}}</span>
    </mat-card-title>
    <mat-divider class="dialog-divider"></mat-divider>
    <mat-card-content class="mat-content-container">
        <div fxLayout="row" fxLayoutAlign="start center">
            <p>{{data.body}}</p>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <button mat-raised-button (click)="sayNo()" class="btn" >{{ 'CONFIRMATIONDIALOG.decline' | translate }}</button>
            <button mat-raised-button (click)="sayYes()" class="btn">{{ 'CONFIRMATIONDIALOG.accept' | translate }}</button>
        </div>
    </mat-card-content>
</mat-card>
