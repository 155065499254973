<div *ngIf="!this.doneLoading" class="loading-center">
	<mat-spinner></mat-spinner><br />{{ 'PASSENGERPAGE.retrieving_booking' | translate }}
</div>
<div *ngIf="this.doneLoading">
	<mat-card class="mat-card-container mat-elevation-z8">
		<mat-card-title class="mat-title">
			<span class="mat-title-span">{{ 'PASSENGERPAGE.passengers' | translate }}</span>
			<a [routerLink]="['/']">
				<mat-icon>arrow_back</mat-icon>
			</a>
		</mat-card-title>
		<mat-divider></mat-divider>
		<mat-card-content class="mat-content-container">
			<div class="info-explanation">
				<mat-icon class="icon-explanation">info</mat-icon>
				<span>{{ 'PASSENGERPAGE.explanation' | translate : { childAge } }}</span>
			</div>
			<div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
				<mat-icon>groups</mat-icon>
				<span>{{ this.passengers.length }} {{ 'PASSENGERPAGE.passenger_amount' | translate }}</span>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-accordion multi>
		<mat-expansion-panel
			*ngFor="let passenger of this.passengers; let i = index"
			class="mat-elevation-z8"
			[expanded]="true"
		>
			<mat-expansion-panel-header>
				<mat-panel-title>{{ i + 1 }}. {{ allForms[i].get('name')?.value }}</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-divider></mat-divider>
			<form [formGroup]="allForms[i]">
				<div fxLayout="row wrap" fxLayoutAlign="start center">
					<mat-form-field class="full-width">
						<mat-label>{{ 'PASSENGERPAGE.name' | translate }}</mat-label>
						<mat-icon matPrefix>account_box</mat-icon>
						<input matInput type="text" formControlName="name" (focusout)="leftFocus(i)" [readonly]="isReadOnly" />
					</mat-form-field>
				</div>
				<!-- DO NOT ASK FOR EMAIL -->
				<!-- <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field class="full-width">
            <mat-label>{{'PASSENGERPAGE.email' | translate}}</mat-label>
            <mat-icon matPrefix>email</mat-icon>
            <input matInput type="email" formControlName="email" (focusout)="leftFocus(i)" [readonly]="isReadOnly"/>
          </mat-form-field>
        </div> -->
				<div fxLayout="row wrap" fxLayoutAlign="start center">
					<mat-form-field class="full-width">
						<mat-label>{{ 'PASSENGERPAGE.age' | translate }}</mat-label>
						<mat-icon matPrefix>diversity_3</mat-icon>
						<input matInput type="number" formControlName="age" (focusout)="leftFocus(i)" [readonly]="isReadOnly" />
					</mat-form-field>
				</div>
				<div fxLayout="row wrap" fxLayoutAlign="start center">
					<mat-form-field class="full-width">
						<mat-label>{{ 'PASSENGERPAGE.weight' | translate }}</mat-label>
						<mat-icon matPrefix>speed</mat-icon>
						<input matInput type="number" formControlName="weight" (focusout)="leftFocus(i)" [readonly]="isReadOnly" />
						<span matSuffix>Kg</span>
					</mat-form-field>
				</div>
				<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
					<span>{{ 'PASSENGERPAGE.child' | translate : { childAge } }}</span>
					<mat-slide-toggle formControlName="child" (change)="leftFocus(i)"> </mat-slide-toggle>
				</div>
				<div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
					<span>{{ 'PASSENGERPAGE.luggage' | translate }}</span>
					<mat-slide-toggle formControlName="luggage" (change)="leftFocus(i)"> </mat-slide-toggle>
				</div>
			</form>
		</mat-expansion-panel>
	</mat-accordion>
</div>
