import { Component } from '@angular/core';
import { UrlService } from 'src/app/_services/url.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';

@Component({
  selector: 'app-chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.css']
})
export class ChatPageComponent {
  myflight: any = null;
  tenantInfo: any = null;

  constructor(private urlService: UrlService, private bpo: BreakpointObserver, private router: Router) {
    this.urlService.myflight.subscribe((myflight) => {
      this.myflight = myflight;
      if (this.myflight && this.myflight.tenantSetting) {
        this.tenantInfo = this.myflight.tenantSetting;
      }
    });
    this.bpo.observe('(min-width: 960px)').subscribe((result) => {
      if (result.matches) {
        this.router.navigate(['/']);
      }
    });
  }
}
