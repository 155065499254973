import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Booking, MyflightFlights } from 'src/gen-types';

@Component({
	selector: 'app-booking-card',
	templateUrl: './booking-card.component.html',
	styleUrls: ['./booking-card.component.css'],
})
export class BookingCardComponent implements OnInit, OnChanges {
	@Input() booking: any = null;
	@Input() operatorSettings: any = null;
	bookingForm: UntypedFormGroup;

	constructor(private formBuilder: UntypedFormBuilder, private router: Router) {
		this.bookingForm = this.formBuilder.group({
			region: '',
			bookingCode: '',
			contactName: '',
			contactEmail: '',
			contactPhone: '',
		});
	}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.booking) {
			this.bookingForm.patchValue(this.booking);
			this.bookingForm.patchValue({ region: this.booking.region.name });
		}
	}
	navigatePax() {
		if (!this.booking.flight || this.booking.flight?.status === 'SCH') {
			this.router.navigate(['passengers']);
		}
	}

	children(booking: Booking) {
		return booking.passengers.filter((p) => p?.child).length;
	}
	disability(booking: Booking) {
		return booking.passengers.filter((p) => p?.disability).length;
	}
	luggage(booking: Booking) {
		return booking.passengers.filter((p) => p?.luggage).length;
	}
}
