import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
// import { UrlService } from './_services/url.service';
// import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import * as moment from 'moment';
import { UrlService } from './_services/url.service';
import { Myflight, MyflightGQL } from 'src/gen-types';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	language = 'nl';
	dateLocale = 'nl-BE';
	bookingInfo: { namespace: string; bookingId: string; legacy: boolean } = {
		namespace: '',
		bookingId: '',
		legacy: false,
	};
	defaultColors = ['#5ca4ff', '#2a2e40', '#ffffff', '#2a2e40'];
	defaultLanguage = 'nl';
	installPromptEvent: any;

	constructor(
		private translate: TranslateService,
		private dateAdapter: DateAdapter<Date>,
		private urlService: UrlService
	) {}

	ngOnInit() {
		// this.setColorScheme(this.defaultColors);
		this.setLanguage(this.defaultLanguage);
		this.urlService.getMyFlight();
		this.urlService.myflight.subscribe((myflight) => {
			if (myflight) {
				if (myflight.booking) this.setLanguage(myflight.booking.contactLanguage);
				if (myflight.tenantSetting?.operatorSettings?.myflight?.colors) {
					this.setColorScheme(myflight.tenantSetting.operatorSettings.myflight.colors);
				} else {
					this.setColorScheme(this.defaultColors);
				}
      } else {
        this.setColorScheme(this.defaultColors);
      }
		});
	}

	setColorScheme(colors: any) {
		document.documentElement.style.setProperty('--primary-background-color', colors[0]);
		document.documentElement.style.setProperty('--secondary-background-color', colors[1]);
		document.documentElement.style.setProperty('--primary-foreground-color', colors[2]);
		document.documentElement.style.setProperty('--secondary-foreground-color', colors[3]);
	}

	setLanguage(language: any) {
		switch (language) {
			case 'nl': {
				this.language = 'nl';
				this.dateLocale = 'nl-BE';
				break;
			}
			case 'fr': {
				this.language = 'fr';
				this.dateLocale = 'fr-BE';
				break;
			}
			case 'en': {
				this.language = 'en';
				this.dateLocale = 'en-US';
				break;
			}
			case 'de': {
				this.language = 'de';
				this.dateLocale = 'de-DE';
				break;
			}
			default:
				this.language = 'nl';
				this.dateLocale = 'nl-BE';
				break;
		}
		this.translate.setDefaultLang(this.language);
		this.translate.use(this.language);
		this.dateAdapter.setLocale(this.dateLocale);
		moment.locale(this.language);
	}

	// Listen for the beforeinstallprompt event
	@HostListener('window:beforeinstallprompt', ['$event'])
	onBeforeInstallPrompt(event: Event) {
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		event.preventDefault();
		// Store the event for later use.
		this.installPromptEvent = event;
	}

	// Call this function when the user clicks a button to install the app.
	promptForInstallation() {
		if (this.installPromptEvent) {
			this.installPromptEvent.prompt(); // show the install app prompt
			this.installPromptEvent.userChoice.then((choiceResult: any) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the install prompt');
				} else {
					console.log('User dismissed the install prompt');
				}
				this.installPromptEvent = null; // clear the saved prompt event
			});
		}
	}
}
